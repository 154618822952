import { CorrectAnswer } from '../lib/domSlide';

export type PdfLessonSlide = {
    type: 'pdf';
    fileName: string;
};

export type AnswerColumn = {
    label?: string;
    words: Array<string>;
};

export type WordListLessonSlide = {
    type: 'wordList';
    hasMemorizationSlide?: boolean;
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    numCol: number;
    hasFreeTextarea?: boolean;
    freeTextareaPlaceholder?: string;
    correctAnswers: Array<CorrectAnswer>;
};

export type ConversionTableLessonSlide = {
    type: 'conversionTable';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    longWord?: boolean;
    correctAnswers: Array<CorrectAnswer>;
};

export type CardConversionTableLessonSlide = {
    type: 'cardConversionTable';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    correctAnswers: Array<CorrectAnswer>;
};

export type SDGsConversionTableLessonSlide = {
    type: 'sdgsConversionTable';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    correctAnswers: Array<CorrectAnswer>;
};

export type CharactersLessonSlide = {
    type: 'characters';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    correctAnswers: Array<CorrectAnswer>;
};

export type ImagesLessonSlide = {
    type: 'images';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    correctAnswers: Array<CorrectAnswer>;
};

export type ImagesWithSentenceLessonSlide = {
    type: 'imagesWithSentence';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    correctAnswers: Array<CorrectAnswer>;
};

export type ManyImagesLessonSlide = {
    type: 'manyImages';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    correctAnswers: Array<CorrectAnswer>;
};

export type CardImagesLessonSlide = {
    type: 'cardImages';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    correctAnswers: Array<CorrectAnswer>;
};

export type HistricalDatesLessonSlide = {
    type: 'histricalDates';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    correctAnswers: Array<CorrectAnswer>;
};

export type SDGsKeywordLessonSlide = {
    type: 'sdgsKeyword';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    has6Rows: boolean;
    correctAnswers: Array<CorrectAnswer>;
};

export type FillInTheBlankLessonSlide = {
    type: 'fillInTheBlank';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    correctAnswers: Array<CorrectAnswer>;
};

export type FillInTheBlankArticleLessonSlide = {
    type: 'fillInTheBlankArticle';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    correctAnswers: Array<CorrectAnswer>;
};

export type RankTableLessonSlide = {
    type: 'rankTable';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    correctAnswers: Array<CorrectAnswer>;
};

export type SentenceLessonSlide = {
    type: 'sentence';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    correctAnswers: Array<CorrectAnswer>;
};

export type KeywordsLessonSlide = {
    type: 'keywords';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    hasMemorizationSlide?: boolean;
    has6Rows: boolean;
    correctAnswers: Array<CorrectAnswer>;
};

export type LongWordsLessonSlide = {
    type: 'longWords';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    hasMemorizationSlide?: boolean;
    correctAnswers: Array<CorrectAnswer>;
};

export type HangulLessonSlide = {
    type: 'hangul';
    memorizationTitle: string;
    recallTitle: string;
    resultTitle?: string;
    hasMemorizationSlide?: boolean;
    correctAnswers: Array<CorrectAnswer>;
};

export type LessonSlide = PdfLessonSlide | WordListLessonSlide | ConversionTableLessonSlide | CharactersLessonSlide |
ImagesLessonSlide | ImagesWithSentenceLessonSlide | ManyImagesLessonSlide | HistricalDatesLessonSlide |
CardConversionTableLessonSlide | CardImagesLessonSlide | SDGsConversionTableLessonSlide | SDGsKeywordLessonSlide |
FillInTheBlankLessonSlide | FillInTheBlankArticleLessonSlide | RankTableLessonSlide | SentenceLessonSlide | KeywordsLessonSlide |
LongWordsLessonSlide | HangulLessonSlide;

export type Lesson = {
    lessonNo: number;
    lessonTitle: string;
    isFree?: boolean;
    slides: Array<LessonSlide>;
};

export enum CourseId {
    introduction = 'introduction',
    storyMethod = 'story-method',
    pegMethod = 'peg-method',
    methodOfLoci = 'method-of-loci',
    number = 'number',
    historicDate = 'historic-date',
    alphabet = 'alphabet',
    nameAndFace = 'name-and-face',
    englishWord = 'english-word',
    textbook = 'textbook',
    memoryProlongation = 'memory-prolongation',
    memorySports = 'memory-sports',
    card = 'card',
    sdgs = 'sdgs',
    worldHeritage = 'world-heritage',
    advancedPegMethod = 'advanced-peg-method',
    circleRatio = 'circle-ratio',
    hangul = 'hangul'
};

export enum CourseColor {
    blue = 'blue',
    red = 'red',
    yellow = 'yellow',
}

export type Course = {
    courseId: CourseId;
    courseTitle: string;
    courseColor: CourseColor,
    isAdvanced: boolean;
    lessons: Array<Lesson>;
};

// コース・レッスン・PDF・スライド内演習の情報
// DB管理すると現在の状況を把握しにくくなるので、長くなるがこのファイルに記述する
// 必要に応じてコースごとに変数分割したほうがいいかも
export const courseConfig: Array<Course> = [
    {
        courseId: CourseId.introduction,
        courseTitle: '記憶術を学ぶ前に',
        courseColor: CourseColor.blue,
        isAdvanced: false,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: '人間は忘れる生き物',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_introduction_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: '記憶って何？①',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_introduction_02_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: '記憶って何？②',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_introduction_03_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: '記憶術とは？',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_introduction_04_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: 'メモリースポーツとは？',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_introduction_05_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: '記憶術に年齢や才能は関係ある？',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_introduction_06_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: '記憶力テストにチャレンジ',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_introduction_07_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: '記憶術には練習が最重要',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_introduction_08_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 9,
                lessonTitle: '日常での記憶とメモリースポーツの違い',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_introduction_09_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 10,
                lessonTitle: '記憶力が高まるとどうなる？',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_introduction_10_1.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.storyMethod,
        courseTitle: 'ストーリー法',
        courseColor: CourseColor.blue,
        isAdvanced: false,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: 'イメージ化',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_story_method_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: 'ストーリー法とは？〜単語を覚える〜',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_story_method_02_1.pdf',
                    },
                    {
                        type: 'wordList',
                        hasMemorizationSlide: true,
                        memorizationTitle: '6個の単語をストーリー法で覚えましょう',
                        recallTitle: '6個の単語を思い出してみましょう',
                        numCol: 3,
                        hasFreeTextarea: true,
                        freeTextareaPlaceholder: '自由にストーリーを書き込んでみましょう',
                        correctAnswers: [
                            {
                                words: [
                                    '猫', 'ネコ', 'ねこ',
                                ],
                            },
                            {
                                words: [
                                    'ラーメン',
                                ],
                            },
                            {
                                words: [
                                    'ボールペン',
                                ],
                            },
                            {
                                words: [
                                    '本',
                                ],
                            },
                            {
                                words: [
                                    'かばん', 'カバン', '鞄',
                                ],
                            },
                            {
                                words: [
                                    'コップ',
                                ],
                            },

                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_story_method_02_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: 'たくさんの単語を覚える',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_story_method_03_1.pdf',
                    },
                    {
                        type: 'wordList',
                        hasMemorizationSlide: true,
                        memorizationTitle: '10個の単語をストーリー法で覚えましょう',
                        recallTitle: '10個の単語を思い出してみましょう',
                        numCol: 5,
                        hasFreeTextarea: true,
                        freeTextareaPlaceholder: '自由にストーリーを書き込んでみましょう',
                        correctAnswers: [
                            {
                                words: [
                                    'カレー',
                                ],
                            },
                            {
                                words: [
                                    'ボート',
                                ],
                            },
                            {
                                words: [
                                    'ぬいぐるみ',
                                ],
                            },
                            {
                                words: [
                                    '箱',
                                ],
                            },
                            {
                                words: [
                                    '手裏剣',
                                ],
                            },
                            {
                                words: [
                                    '靴下',
                                    'くつ下',
                                    'くつした',
                                ],
                            },
                            {
                                words: [
                                    'ノート',
                                ],
                            },
                            {
                                words: [
                                    '馬',
                                ],
                            },
                            {
                                words: [
                                    'ドア',
                                ],
                            },
                            {
                                words: [
                                    'ひまわり',
                                ],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_story_method_03_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: '抽象的な単語を覚える',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_story_method_04_1.pdf',
                    },
                    {
                        type: 'wordList',
                        hasMemorizationSlide: true,
                        memorizationTitle: '10個の単語をストーリー法で覚えましょう',
                        recallTitle: '10個の単語を思い出してみましょう',
                        numCol: 5,
                        hasFreeTextarea: true,
                        freeTextareaPlaceholder: '自由にストーリーを書き込んでみましょう',
                        correctAnswers: [
                            {
                                words: [
                                    '友情',
                                ],
                            },
                            {
                                words: [
                                    '愛',
                                ],
                            },
                            {
                                words: [
                                    '社長',
                                ],
                            },
                            {
                                words: [
                                    '痛み',
                                ],
                            },
                            {
                                words: [
                                    '反射',
                                ],
                            },
                            {
                                words: [
                                    '大統領',
                                ],
                            },
                            {
                                words: [
                                    '優勝',
                                ],
                            },
                            {
                                words: [
                                    '修行',
                                ],
                            },
                            {
                                words: [
                                    '徒歩',
                                ],
                            },
                            {
                                words: [
                                    '勉強',
                                ],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_story_method_04_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: '様々な品詞の単語を覚える',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_story_method_05_1.pdf',
                    },
                    {
                        type: 'wordList',
                        hasMemorizationSlide: true,
                        memorizationTitle: '10個の単語をストーリー法で覚えましょう',
                        recallTitle: '10個の単語を思い出してみましょう',
                        numCol: 5,
                        hasFreeTextarea: true,
                        freeTextareaPlaceholder: '自由にストーリーを書き込んでみましょう',
                        correctAnswers: [
                            {
                                words: [
                                    '走る',
                                ],
                            },
                            {
                                words: [
                                    'たくさん', '沢山',
                                ],
                            },
                            {
                                words: [
                                    '飲む',
                                ],
                            },
                            {
                                words: [
                                    '恋人',
                                ],
                            },
                            {
                                words: [
                                    'ちょっと',
                                ],
                            },
                            {
                                words: [
                                    '青い',
                                ],
                            },
                            {
                                words: [
                                    '液体',
                                ],
                            },
                            {
                                words: [
                                    '寝る',
                                ],
                            },
                            {
                                words: [
                                    '白い',
                                ],
                            },
                            {
                                words: [
                                    '投げる',
                                ],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_story_method_05_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: '覚えにくい形をした単語を覚える',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_story_method_06_1.pdf',
                    },
                    {
                        type: 'wordList',
                        hasMemorizationSlide: true,
                        memorizationTitle: '10個の単語をストーリー法で覚えましょう',
                        recallTitle: '10個の単語を思い出してみましょう',
                        numCol: 5,
                        hasFreeTextarea: true,
                        freeTextareaPlaceholder: '自由にストーリーを書き込んでみましょう',
                        correctAnswers: [
                            {
                                words: [
                                    'イルカ',
                                ],
                            },
                            {
                                words: [
                                    '胃',
                                ],
                            },
                            {
                                words: [
                                    '寒い',
                                ],
                            },
                            {
                                words: [
                                    '砂漠',
                                ],
                            },
                            {
                                words: [
                                    '王',
                                ],
                            },
                            {
                                words: [
                                    '行く',
                                ],
                            },
                            {
                                words: [
                                    'チルド室',
                                ],
                            },
                            {
                                words: [
                                    'パルクール',
                                ],
                            },
                            {
                                words: [
                                    '九',
                                ],
                            },
                            {
                                words: [
                                    '湿布',
                                ],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_story_method_06_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: 'ストーリー法のメリット＆デメリット',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_story_method_07_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: 'まとめ',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_story_method_08_1.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.pegMethod,
        courseTitle: 'ペグ法',
        courseColor: CourseColor.blue,
        isAdvanced: false,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: 'ペグ法とは？',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_peg_method_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: 'ペグ法 1in1システム',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_peg_method_02_1.pdf',
                    },
                    {
                        type: 'wordList',
                        hasMemorizationSlide: true,
                        memorizationTitle: '5個の単語をペグ法で覚えましょう',
                        recallTitle: '5個の単語を思い出してみましょう',
                        numCol: 5,
                        hasFreeTextarea: true,
                        freeTextareaPlaceholder: '自由にストーリーを書き込んでみましょう',
                        correctAnswers: [
                            {
                                words: [
                                    '紙コップ',
                                ],
                            },
                            {
                                words: [
                                    '湯葉',
                                ],
                            },
                            {
                                words: [
                                    'ウイスキー',
                                ],
                            },
                            {
                                words: [
                                    'おはし',
                                    'お箸',
                                ],
                            },
                            {
                                words: [
                                    'バスタオル',
                                ],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_peg_method_02_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: 'ペグ法 2in1システム',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_peg_method_03_1.pdf',
                    },
                    {
                        type: 'wordList',
                        hasMemorizationSlide: true,
                        memorizationTitle: '10個の単語をペグ法で覚えましょう',
                        recallTitle: '10個の単語を思い出してみましょう',
                        numCol: 5,
                        hasFreeTextarea: true,
                        freeTextareaPlaceholder: '自由にストーリーを書き込んでみましょう',
                        correctAnswers: [
                            {
                                words: [
                                    '野菜ジュース',
                                ],
                            },
                            {
                                words: [
                                    'ぞうきん',
                                    '雑巾',
                                ],
                            },
                            {
                                words: [
                                    'ベル',
                                ],
                            },
                            {
                                words: [
                                    '消しゴム',
                                ],
                            },
                            {
                                words: [
                                    '信号機',
                                ],
                            },
                            {
                                words: [
                                    '糸',
                                ],
                            },
                            {
                                words: [
                                    'スカート',
                                ],
                            },
                            {
                                words: [
                                    '枝',
                                ],
                            },
                            {
                                words: [
                                    'タイヤ',
                                ],
                            },
                            {
                                words: [
                                    'トング',
                                ],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_peg_method_03_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: 'ペグ法 3in1システム',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_peg_method_04_1.pdf',
                    },
                    {
                        type: 'wordList',
                        hasMemorizationSlide: true,
                        memorizationTitle: '12個の単語をペグ法で覚えましょう',
                        recallTitle: '12個の単語を思い出してみましょう',
                        numCol: 4,
                        hasFreeTextarea: true,
                        freeTextareaPlaceholder: '自由にストーリーを書き込んでみましょう',
                        correctAnswers: [
                            {
                                words: [
                                    'くぎ',
                                    '釘',
                                ],
                            },
                            {
                                words: [
                                    'ピザ',
                                ],
                            },
                            {
                                words: [
                                    'カメラ',
                                ],
                            },
                            {
                                words: [
                                    'マスク',
                                ],
                            },
                            {
                                words: [
                                    'ハンドタオル',
                                ],
                            },
                            {
                                words: [
                                    'コンセント',
                                ],
                            },
                            {
                                words: [
                                    'ドアノブ',
                                ],
                            },
                            {
                                words: [
                                    'ボードゲーム',
                                ],
                            },
                            {
                                words: [
                                    'パンツ',
                                ],
                            },
                            {
                                words: [
                                    'トースト',
                                ],
                            },
                            {
                                words: [
                                    'アリ',
                                    'あり',
                                    '蟻',
                                ],
                            },
                            {
                                words: [
                                    'シマウマ',
                                ],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_peg_method_04_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: 'ペグ法 4in1システム',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_peg_method_05_1.pdf',
                    },
                    {
                        type: 'wordList',
                        hasMemorizationSlide: true,
                        memorizationTitle: '12個の単語をペグ法で覚えましょう',
                        recallTitle: '12個の単語を思い出してみましょう',
                        numCol: 4,
                        hasFreeTextarea: true,
                        freeTextareaPlaceholder: '自由にストーリーを書き込んでみましょう',
                        correctAnswers: [
                            {
                                words: [
                                    '保冷剤',
                                ],
                            },
                            {
                                words: [
                                    'タイマー',
                                ],
                            },
                            {
                                words: [
                                    'プロテイン',
                                ],
                            },
                            {
                                words: [
                                    'きっぷ',
                                    '切符',
                                ],
                            },
                            {
                                words: [
                                    'パンプス',
                                ],
                            },
                            {
                                words: [
                                    '青汁',
                                ],
                            },
                            {
                                words: [
                                    '薬',
                                ],
                            },
                            {
                                words: [
                                    'シール',
                                ],
                            },
                            {
                                words: [
                                    'キャベツ',
                                ],
                            },
                            {
                                words: [
                                    '口紅',
                                ],
                            },
                            {
                                words: [
                                    'フライドチキン',
                                ],
                            },
                            {
                                words: [
                                    'きんちゃく袋',
                                    '巾着袋',
                                ],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_peg_method_05_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: 'ペグ法のシステム比較',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_peg_method_06_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: 'ペグ法 まとめ＆ペグを作ってみる',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_peg_method_07_1.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.methodOfLoci,
        courseTitle: '場所法',
        courseColor: CourseColor.blue,
        isAdvanced: false,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: '場所法とは',
                isFree: true,
                slides: [
                    // TODO: レッスン一覧でレッスン名が違うので修正する
                    {
                        type: 'pdf',
                        fileName: 'slide_method_of_loci_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: '場所法の例',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_method_of_loci_02_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: '場所作りのポイント',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_method_of_loci_03_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: '場所を作ってみよう①',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_method_of_loci_04_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: '場所を使ってみよう！',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_method_of_loci_05_1.pdf',
                    },
                    {
                        type: 'wordList',
                        hasMemorizationSlide: true,
                        memorizationTitle: '10個の単語を場所法で覚えましょう',
                        recallTitle: '覚えた単語を思い出してみましょう',
                        numCol: 5,
                        hasFreeTextarea: false,
                        freeTextareaPlaceholder: '',
                        correctAnswers: [
                            '消しゴム', '犬', 'コップ', '靴', 'ネクタイ',
                            '野球', '神社', 'キャベツ', '金魚', 'ハサミ',
                        ].map(w => ( { words: [w], } )),
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_method_of_loci_05_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: '場所磨きのポイント',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_method_of_loci_06_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: '場所を作ってみよう②',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_method_of_loci_07_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: '場所法 2in1システム',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_method_of_loci_08_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 9,
                lessonTitle: '2in1システムにチャレンジ！',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_method_of_loci_09_1.pdf',
                    },
                    {
                        type: 'wordList',
                        hasMemorizationSlide: true,
                        memorizationTitle: '12個の単語を場所法(2in1)で覚えましょう',
                        recallTitle: '覚えた単語を思い出してみましょう',
                        numCol: 4,
                        hasFreeTextarea: false,
                        freeTextareaPlaceholder: '',
                        correctAnswers: [
                            '寿司', '遊園地', 'ナイフ', 'ブランコ',
                            'トマト', '映画', 'カメラ', '手品',
                            'ホトトギス', '鬼ごっこ', 'カステラ', 'ろくろ首',
                        ].map(w => ( { words: [w], } )),
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_method_of_loci_09_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 10,
                lessonTitle: '1プレイスに置く数',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_method_of_loci_10_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 11,
                lessonTitle: '場所法まとめ',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_method_of_loci_11_1.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.number,
        courseTitle: '数字記憶法',
        courseColor: CourseColor.blue,
        isAdvanced: false,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: '数字記憶法とは',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: '1桁1イメージ',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_02_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        // resultTitle: '',
                        correctAnswers: [
                            {
                                label: '0',
                                words: ['冷蔵庫'],
                            },
                            {
                                label: '1',
                                words: ['イチゴ', 'いちご', '苺'],
                            },
                            {
                                label: '2',
                                words: ['にんじん', 'ニンジン', '人参'],
                            },
                            {
                                label: '3',
                                words: ['サンマ', 'さんま', '秋刀魚'],
                            },
                            {
                                label: '4',
                                words: ['鹿', 'シカ', 'しか'],
                            },
                            {
                                label: '5',
                                words: ['ごはん', 'ご飯'],
                            },
                            {
                                label: '6',
                                words: ['ロウソク', 'ろうそく', '蝋燭'],
                            },
                            {
                                label: '7',
                                words: ['ナイフ'],
                            },
                            {
                                label: '8',
                                words: ['蜂', 'ハチ', 'はち'],
                            },
                            {
                                label: '9',
                                words: ['きゅうり', 'キュウリ', '胡瓜'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_02_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: '1桁1イメージで覚えてみよう',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_03_1.pdf',
                    },
                    {
                        type: 'characters',
                        memorizationTitle: '10桁の数字を覚えましょう',
                        recallTitle: '覚えたものを回答してみましょう',
                        // resultTitle: ''
                        correctAnswers: [...'2867 1944 07'.replace(/ /g, '')].map(c => ({ words: [c], })),
                    },
                   {
                        type: 'pdf',
                        fileName: 'slide_number_03_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: '2桁1イメージ',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_04_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        // resultTitle: ''
                        correctAnswers: [
                            {
                                label: '00',
                                words: ['おわん', 'お椀'],
                            },
                            {
                                label: '01',
                                words: ['ワイン'],
                            },
                            {
                                label: '02',
                                words: ['ワカメ', 'わかめ'],
                            },
                            {
                                label: '03',
                                words: ['お酢'],
                            },
                            {
                                label: '04',
                                words: ['わたあめ'],
                            },
                            {
                                label: '05',
                                words: ['鬼', 'オニ'],
                            },
                            {
                                label: '06',
                                words: ['お箸', 'おはし'],
                            },
                            {
                                label: '07',
                                words: ['おみくじ'],
                            },
                            {
                                label: '08',
                                words: ['お湯'],
                            },
                            {
                                label: '09',
                                words: ['おりがみ', '折り紙'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_04_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: '2桁1イメージで覚えてみよう',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_05_1.pdf',
                    },
                    {
                        type: 'characters',
                        memorizationTitle: '12桁の数字を覚えましょう',
                        recallTitle: '覚えたものを回答してみましょう',
                        // resultTitle: ''
                        correctAnswers: [...'030007010408'].map(c => ({ words: [c], })),
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_05_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: '10〜19の変換',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_06_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        // resultTitle: ''
                        correctAnswers: [
                            {
                                label: '10',
                                words: ['岩'],
                            },
                            {
                                label: '11',
                                words: ['アイロン'],
                            },
                            {
                                label: '12',
                                words: ['赤ちゃん'],
                            },
                            {
                                label: '13',
                                words: ['足'],
                            },
                            {
                                label: '14',
                                words: ['板'],
                            },
                            {
                                label: '15',
                                words: ['イナゴ', 'いなご'],
                            },
                            {
                                label: '16',
                                words: ['アヒル', 'あひる'],
                            },
                            {
                                label: '17',
                                words: ['網', 'あみ'],
                            },
                            {
                                label: '18',
                                words: ['鮎', 'あゆ'],
                            },
                            {
                                label: '19',
                                words: ['アリ', 'あり', '蟻'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_06_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: '20〜29の変換',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_07_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        // resultTitle: ''
                        correctAnswers: [
                            {
                                label: '20',
                                words: ['コンピュータ'],
                            },
                            {
                                label: '21',
                                words: ['貝殻'],
                            },
                            {
                                label: '22',
                                words: ['柿'],
                            },
                            {
                                label: '23',
                                words: ['傘', 'かさ', 'カサ'],
                            },
                            {
                                label: '24',
                                words: ['カタツムリ', 'かたつむり', '蝸牛'],
                            },
                            {
                                label: '25',
                                words: ['カニ', 'かに', '蟹'],
                            },
                            {
                                label: '26',
                                words: ['カバン', 'かばん', '鞄'],
                            },
                            {
                                label: '27',
                                words: ['ガム'],
                            },
                            {
                                label: '28',
                                words: ['カヤック'],
                            },
                            {
                                label: '29',
                                words: ['カラス'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_07_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: '数字ペグ法',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_08_1.pdf',
                    },
                    {
                        type: 'wordList',
                        hasMemorizationSlide: true,
                        memorizationTitle: '12個の単語を数字ペグ法で覚えましょう',
                        recallTitle: '12個の単語を思い出してみましょう',
                        numCol: 4,
                        hasFreeTextarea: false,
                        freeTextareaPlaceholder: '',
                        correctAnswers: [
                            {
                                words: [
                                    'ピアノ',
                                ],
                            },
                            {
                                words: [
                                    '夏',
                                ],
                            },
                            {
                                words: [
                                    'みかん',
                                ],
                            },
                            {
                                words: [
                                    'ダンス',
                                ],
                            },
                            {
                                words: [
                                    'お茶',
                                ],
                            },
                            {
                                words: [
                                    'ピザ',
                                ],
                            },
                            {
                                words: [
                                    'ゴルフ',
                                ],
                            },
                            {
                                words: [
                                    '田舎',
                                ],
                            },
                            {
                                words: [
                                    '医者',
                                ],
                            },
                            {
                                words: [
                                    'ポスト',
                                ],
                            },
                            {
                                words: [
                                    'トイレ',
                                ],
                            },
                            {
                                words: [
                                    '自転車',
                                ],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_08_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 9,
                lessonTitle: '30〜39の変換',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_09_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        // resultTitle: ''
                        correctAnswers: [
                            {
                                label: '30',
                                words: ['竿', 'さお'],
                            },
                            {
                                label: '31',
                                words: ['サイダー'],
                            },
                            {
                                label: '32',
                                words: ['酒'],
                            },
                            {
                                label: '33',
                                words: ['笹'],
                            },
                            {
                                label: '34',
                                words: ['砂糖'],
                            },
                            {
                                label: '35',
                                words: ['砂時計'],
                            },
                            {
                                label: '36',
                                words: ['スパイ'],
                            },
                            {
                                label: '37',
                                words: ['サメ', '鮫'],
                            },
                            {
                                label: '38',
                                words: ['さやえんどう', 'サヤエンドウ'],
                            },
                            {
                                label: '39',
                                words: ['皿'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_09_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 10,
                lessonTitle: '40〜49の変換',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_10_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        // resultTitle: ''
                        correctAnswers: [
                            {
                                label: '40',
                                words: ['たわし'],
                            },
                            {
                                label: '41',
                                words: ['たい焼き', 'たいやき', '鯛焼き'],
                            },
                            {
                                label: '42',
                                words: ['タコ', 'たこ', '蛸'],
                            },
                            {
                                label: '43',
                                words: ['タスキ', 'たすき'],
                            },
                            {
                                label: '44',
                                words: ['畳', 'たたみ'],
                            },
                            {
                                label: '45',
                                words: ['棚'],
                            },
                            {
                                label: '46',
                                words: ['タバコ', 'たばこ'],
                            },
                            {
                                label: '47',
                                words: ['ダム'],
                            },
                            {
                                label: '48',
                                words: ['チョコレート'],
                            },
                            {
                                label: '49',
                                words: ['たらこ'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_10_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 11,
                lessonTitle: '実際に覚えてみよう',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_11_1.pdf',
                    },
                    {
                        type: 'characters',
                        memorizationTitle: '20桁の数字を覚えましょう',
                        recallTitle: '覚えたものを回答してみましょう',
                        // resultTitle: ''
                        correctAnswers: [...'4230 4721 3438 2217 1209'.replace(/ /g, '')].map(c => ({ words: [c], })),
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_11_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 12,
                lessonTitle: '50〜59の変換',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_12_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        // resultTitle: ''
                        correctAnswers: [
                            {
                                label: '50',
                                words: ['縄'],
                            },
                            {
                                label: '51',
                                words: ['ぬいぐるみ'],
                            },
                            {
                                label: '52',
                                words: ['猫', 'ねこ', 'ネコ'],
                            },
                            {
                                label: '53',
                                words: ['なす', 'ナス', '茄子'],
                            },
                            {
                                label: '54',
                                words: ['納豆', 'なっとう'],
                            },
                            {
                                label: '55',
                                words: ['布'],
                            },
                            {
                                label: '56',
                                words: ['鍋', 'なべ', 'ナベ'],
                            },
                            {
                                label: '57',
                                words: ['生ビール'],
                            },
                            {
                                label: '58',
                                words: ['納屋'],
                            },
                            {
                                label: '59',
                                words: ['ニラ', 'にら', '韮'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_12_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 13,
                lessonTitle: '60〜69の変換',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_13_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        // resultTitle: ''
                        correctAnswers: [
                            {
                                label: '60',
                                words: ['びわ', 'ビワ'],
                            },
                            {
                                label: '61',
                                words: ['ハエ', '蝿', '蠅'],
                            },
                            {
                                label: '62',
                                words: ['墓'],
                            },
                            {
                                label: '63',
                                words: ['ハサミ', 'はさみ'],
                            },
                            {
                                label: '64',
                                words: ['旗', 'はた'],
                            },
                            {
                                label: '65',
                                words: ['花火'],
                            },
                            {
                                label: '66',
                                words: ['葉っぱ', 'はっぱ'],
                            },
                            {
                                label: '67',
                                words: ['ハム'],
                            },
                            {
                                label: '68',
                                words: ['ハヤシライス'],
                            },
                            {
                                label: '69',
                                words: ['針'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_13_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 14,
                lessonTitle: '70〜79の変換',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_14_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        // resultTitle: ''
                        correctAnswers: [
                            {
                                label: '70',
                                words: ['マントヒヒ'],
                            },
                            {
                                label: '71',
                                words: ['麻婆豆腐', 'マーボー豆腐', 'マーボーどうふ'],
                            },
                            {
                                label: '72',
                                words: ['薪', 'まき'],
                            },
                            {
                                label: '73',
                                words: ['みそ', '味噌', 'ミソ'],
                            },
                            {
                                label: '74',
                                words: ['ムチ'],
                            },
                            {
                                label: '75',
                                words: ['ミニトマト'],
                            },
                            {
                                label: '76',
                                words: ['魔法使い'],
                            },
                            {
                                label: '77',
                                words: ['耳栓'],
                            },
                            {
                                label: '78',
                                words: ['マヨネーズ'],
                            },
                            {
                                label: '79',
                                words: ['マラカス'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_14_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 15,
                lessonTitle: '80〜89の変換',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_15_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        // resultTitle: ''
                        correctAnswers: [
                            {
                                label: '80',
                                words: ['ヤンキー'],
                            },
                            {
                                label: '81',
                                words: ['妖怪'],
                            },
                            {
                                label: '82',
                                words: ['やかん'],
                            },
                            {
                                label: '83',
                                words: ['ヤシの木'],
                            },
                            {
                                label: '84',
                                words: ['屋台'],
                            },
                            {
                                label: '85',
                                words: ['湯のみ'],
                            },
                            {
                                label: '86',
                                words: ['指輪'],
                            },
                            {
                                label: '87',
                                words: ['山'],
                            },
                            {
                                label: '88',
                                words: ['ヨーヨー'],
                            },
                            {
                                label: '89',
                                words: ['槍', 'やり'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_15_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 16,
                lessonTitle: '90〜99の変換',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_16_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        // resultTitle: ''
                        correctAnswers: [
                            {
                                label: '90',
                                words: ['ランドセル'],
                            },
                            {
                                label: '91',
                                words: ['ライオン'],
                            },
                            {
                                label: '92',
                                words: ['リコーダー'],
                            },
                            {
                                label: '93',
                                words: ['リス', 'りす'],
                            },
                            {
                                label: '94',
                                words: ['レタス'],
                            },
                            {
                                label: '95',
                                words: ['リニア'],
                            },
                            {
                                label: '96',
                                words: ['ラッパ'],
                            },
                            {
                                label: '97',
                                words: ['リモコン'],
                            },
                            {
                                label: '98',
                                words: ['ラー油'],
                            },
                            {
                                label: '99',
                                words: ['レール'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_16_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 17,
                lessonTitle: '数字記憶法のまとめ',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_number_17_1.pdf',
                    },
                    {
                        type: 'characters',
                        memorizationTitle: '円周率20桁を覚えましょう',
                        recallTitle: '覚えたものを回答してみましょう',
                        // resultTitle: ''
                        correctAnswers: [...'31415926535897932384'].map(c => ({ words: [c], })),
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_number_17_2.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.historicDate,
        courseTitle: '年号記憶法',
        courseColor: CourseColor.blue,
        isAdvanced: false,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: '2桁の年号記憶法',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_historic_date_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: '3桁の年号記憶法',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_historic_date_02_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: '3桁 練習問題',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_historic_date_03_1.pdf',
                    },
                    {
                        type: 'histricalDates',
                        memorizationTitle: '年号を覚えましょう',
                        recallTitle: '出来事から年号を思い出しましょう',
                        correctAnswers: [
                            {
                                words: ['593'],
                                sentence: '聖徳太子が推古天皇の摂政になる',
                                secondOrder: 3,
                            },
                            {
                                words: ['720'],
                                sentence: '日本書紀が発行される',
                                secondOrder: 1,
                            },
                            {
                                words: ['894'],
                                sentence: '遣唐使を廃止する',
                                secondOrder: 2,
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_historic_date_03_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: '4桁の年号記憶法',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_historic_date_04_1.pdf',
                    },
                    {
                        type: 'histricalDates',
                        memorizationTitle: '年号を覚えましょう',
                        recallTitle: '出来事から年号を思い出しましょう',
                        correctAnswers: [
                            {
                                words: ['1016'],
                                sentence: '藤原道長が摂政になる',
                                secondOrder: 2,
                            },
                            {
                                words: ['1549'],
                                sentence: 'キリスト教が伝来する',
                                secondOrder: 1,
                            },
                            {
                                words: ['1853'],
                                sentence: '黒船に乗ったペリーが来航する',
                                secondOrder: 4,
                            },
                            {
                                words: ['1989'],
                                sentence: 'ベルリンの壁が崩壊する',
                                secondOrder: 3,
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_historic_date_04_2.pdf',
                    },

                ],
            },
            {
                lessonNo: 5,
                lessonTitle: '4桁 ストーリー例',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_historic_date_05_1.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.alphabet,
        courseTitle: 'アルファベット記憶法',
        courseColor: CourseColor.blue,
        isAdvanced: false,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: 'アルファベット記憶法とは',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: 'a~fの変換',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_02_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう (英単語のみでOK)',
                        // resultTitle: ''
                        correctAnswers: [
                            {
                                label: 'a',
                                words: ['apple (リンゴ)', 'apple'],
                            },
                            {
                                label: 'b',
                                words: ['bus (バス)', 'bus'],
                            },
                            {
                                label: 'c',
                                words: ['candy (飴)', 'candy'],
                            },
                            {
                                label: 'd',
                                words: ['dice (サイコロ)', 'dice'],
                            },
                            {
                                label: 'e',
                                words: ['egg (卵)', 'egg'],
                            },
                            {
                                label: 'f',
                                words: ['face (顔)', 'face'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_02_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: 'g~pの変換',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_03_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう (英単語のみでOK)',
                        // resultTitle: ''
                        correctAnswers: [
                            {
                                label: 'g',
                                words: ['guitar (ギター)', 'guitar'],
                            },
                            {
                                label: 'h',
                                words: ['hotel (ホテル)', 'hotel'],
                            },
                            {
                                label: 'i',
                                words: ['ink (インク)', 'ink'],
                            },
                            {
                                label: 'j',
                                words: ['jet (ジェット機)', 'jet'],
                            },
                            {
                                label: 'k',
                                words: ['koala (コアラ)', 'koala'],
                            },
                            {
                                label: 'l',
                                words: ['lemon (レモン)', 'lemon'],
                            },
                            {
                                label: 'm',
                                words: ['monster (化け物)', 'monster'],
                            },
                            {
                                label: 'n',
                                words: ['nurse (看護師)', 'nurse'],
                            },
                            {
                                label: 'o',
                                words: ['ox (牛)', 'ox'],
                            },
                            {
                                label: 'p',
                                words: ['pencil (鉛筆)', 'pencil'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_03_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: 'アルファベット記憶法 実践①',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_04_1.pdf',
                    },
                    {
                        type: 'characters',
                        memorizationTitle: 'アルファベットを覚えてみましょう',
                        recallTitle: '覚えたものを回答してみましょう',
                        // resultTitle: ''
                        correctAnswers: [...'kjgibadefh'].map(c => ({ words: [c], })),
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_04_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: 'q~zの変換',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_05_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう (英単語のみでOK)',
                        // resultTitle: ''
                        correctAnswers: [
                            {
                                label: 'q',
                                words: ['queen (女王)', 'queen'],
                            },
                            {
                                label: 'r',
                                words: ['radio (ラジオ)', 'radio'],
                            },
                            {
                                label: 's',
                                words: ['socks (靴下)', 'socks'],
                            },
                            {
                                label: 't',
                                words: ['table (テーブル)', 'table'],
                            },
                            {
                                label: 'u',
                                words: ['UFO (ユーフォー)', 'UFO'],
                            },
                            {
                                label: 'v',
                                words: ['virus (ウイルス)', 'virus'],
                            },
                            {
                                label: 'w',
                                words: ['water (水)', 'water'],
                            },
                            {
                                label: 'x',
                                words: ['Xmas (クリスマスツリー)', 'Xmas tree'],
                            },
                            {
                                label: 'y',
                                words: ['yacht (ヨット)', 'yacht'],
                            },
                            {
                                label: 'z',
                                words: ['zebra (シマウマ)', 'zebra'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_05_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: 'アルファベット記憶法 実践②',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_06_1.pdf',
                    },
                    {
                        type: 'characters',
                        memorizationTitle: 'アルファベットを覚えてみましょう',
                        recallTitle: '覚えたものを回答してみましょう',
                        // resultTitle: ''
                        correctAnswers: [...'xvtwzyqrus'].map(c => ({ words: [c], })),
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_06_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: '大文字と小文字の識別',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_07_1.pdf',
                    },
                    {
                        type: 'characters',
                        memorizationTitle: 'アルファベットを覚えてみましょう',
                        recallTitle: '覚えたものを回答してみましょう',
                        // resultTitle: ''
                        correctAnswers: [...'KjtFsmVHez'].map(c => ({ words: [c], })),
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_07_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: 'アルファベット記憶法 応用',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_08_1.pdf',
                    },
                    {
                        type: 'characters',
                        memorizationTitle: 'アルファベットを覚えてみましょう',
                        recallTitle: '覚えたものを回答してみましょう',
                        // resultTitle: ''
                        correctAnswers: [...'Y73okaqnm4'].map(c => ({ words: [c], })),
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_alphabet_08_2.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.nameAndFace,
        courseTitle: '顔と名前記憶法',
        courseColor: CourseColor.blue,
        isAdvanced: false,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: '名前が覚えられない理由',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_name_and_face_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: 'タグ付け法',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_name_and_face_02_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: '変換例',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_name_and_face_03_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: '名前を物に置き換える',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_name_and_face_04_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: '複数に分解する',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_name_and_face_05_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: 'フルネーム記憶',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_name_and_face_06_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: 'プロフィール記憶',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_name_and_face_07_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: '人に名前を覚えてもらう方法',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_name_and_face_08_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 9,
                lessonTitle: '工夫できるポイント',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_name_and_face_09_1.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.englishWord,
        courseTitle: '英単語記憶法',
        courseColor: CourseColor.blue,
        isAdvanced: false,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: '英単語はなぜ覚えられないのか？',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: '英単語を覚える前に',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_02_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: '覚えやすいパターンを選ぶ',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_03_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: '場面で記憶する',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_04_1.pdf',
                    },
                    {
                        type: 'longWords',
                        memorizationTitle: '6個の単語を覚えましょう',
                        recallTitle: '6個の単語を思い出しましょう',
                        resultTitle: '結果',
                        hasMemorizationSlide: true,
                        correctAnswers: [
                            {
                                label: 'conceal',
                                words: ['隠す'],
                            },
                            {
                                label: 'device',
                                words: ['装置'],
                            },
                            {
                                label: 'credit',
                                words: ['信用'],
                            },
                            {
                                label: 'priority',
                                words: ['優先'],
                            },
                            {
                                label: 'fortune',
                                words: ['幸運'],
                            },
                            {
                                label: 'puzzle',
                                words: ['困惑させる'],
                            },
                        ],                       

                    },

                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_04_2.pdf',
                    },

                ],
            },
            {
                lessonNo: 5,
                lessonTitle: '語源で記憶する',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_05_1.pdf',
                    },
                    {
                        type: 'longWords',
                        memorizationTitle: '6個の単語を覚えましょう',
                        recallTitle: '6個の単語を思い出しましょう',
                        resultTitle: '結果',
                        hasMemorizationSlide: true,
                        correctAnswers: [
                            {
                                label: 'reaction',
                                words: ['反応'],
                            },
                            {
                                label: 'encourage',
                                words: ['勇気づける'],
                            },
                            {
                                label: 'exchange',
                                words: ['交換する'],
                            },
                            {
                                label: 'headline',
                                words: ['見出し'],
                            },
                            {
                                label: 'transport',
                                words: ['輸送する'],
                            },
                            {
                                label: 'depopulation',
                                words: ['人口減少',],
                            },
                        ],                       

                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_05_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: '英単語＆日本語訳でストーリー化',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_06_1.pdf',
                    },
                    {
                        type: 'longWords',
                        memorizationTitle: '6個の単語を覚えましょう',
                        recallTitle: '6個の単語を思い出しましょう',
                        resultTitle: '結果',
                        hasMemorizationSlide: true,
                        correctAnswers: [
                            {
                                label: 'achieve',
                                words: ['達成する'],
                            },
                            {
                                label: 'belief',
                                words: ['信念'],
                            },
                            {
                                label: 'amend',
                                words: ['修正する'],
                            },
                            {
                                label: 'intermediate',
                                words: ['中間の'],
                            },
                            {
                                label: 'compensate',
                                words: ['補償する'],
                            },
                            {
                                label: 'sensitive',
                                words: ['敏感な'],
                            },

                        ],                       

                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_06_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: '英単語を記憶する4つのステップ',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_07_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: 'イメージを場所に置く',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_08_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 9,
                lessonTitle: '場所をたどる',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_09_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 10,
                lessonTitle: '英単語から日本語訳を確認する',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_10_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 11,
                lessonTitle: 'さらに効率良く覚えるために',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_11_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 12,
                lessonTitle: '英単語を書けるようにする方法',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_12_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 13,
                lessonTitle: 'おすすめ英語学習法',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_13_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 14,
                lessonTitle: '実際に英単語を覚えてみる',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_english_word_14_1.pdf',
                    },
                ],
            },

        ],
    },
    {
        courseId: CourseId.textbook,
        courseTitle: '本＆教科書記憶法',
        courseColor: CourseColor.blue,
        isAdvanced: false,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: '本＆教科書記憶法とは？',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_textbook_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: '範囲や内容を確認し戦略を立てる',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_textbook_02_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: '内容をしっかり読み込み理解する',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_textbook_03_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: '内容に含まれるキーワードを抜き出す',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_textbook_04_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: 'キーワードを記憶する場所法',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_textbook_05_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: 'キーワードを記憶するページ記憶法',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_textbook_06_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: '覚えたキーワードから内容を再現する',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_textbook_07_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: 'よくある疑問',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_textbook_08_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 9,
                lessonTitle: '実際に教科書を覚えてみる',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_textbook_09_1.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.memoryProlongation,
        courseTitle: '記憶を長期化する方法',
        courseColor: CourseColor.blue,
        isAdvanced: false,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: '長期記憶の仕組み',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_prolongation_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: '視覚化',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_prolongation_02_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: 'マッピング',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_prolongation_03_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: '記憶ノート',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_prolongation_04_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: 'ノートの取り方',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_prolongation_05_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: '記憶と時間',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_prolongation_06_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: '復習回数と質',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_prolongation_07_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: '記憶の定着を良くするコツ',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_prolongation_08_1.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.memorySports,
        courseTitle: 'メモリースポーツとは',
        courseColor: CourseColor.yellow,
        isAdvanced: true,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: 'What is メモリースポーツ？',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_sports_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: 'メモリースポーツの歴史',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_sports_02_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: '強豪国とスター選手',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_sports_03_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: 'Why メモリースポーツ！？',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_sports_04_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: 'How to play メモリースポーツ！？',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_sports_05_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: 'メモリースポーツに挑戦 イメージ記憶編',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_sports_06_1.pdf',
                    },
                    {
                        type: 'images',
                        memorizationTitle: '写真の順番を記憶してみましょう',
                        recallTitle: '写真の下のプルダウンで記憶した順番を選択してください',
                        correctAnswers: [
                            {
                                words: ['1'],
                                image: 'slide_memory_sports_06_1.jpg',
                                secondOrder: 2,
                             },
                            {
                                words: ['2'],
                                image: 'slide_memory_sports_06_2.jpg',
                                secondOrder: 5,
                            },
                            {
                                words: ['3'],
                                image: 'slide_memory_sports_06_3.jpg',
                                secondOrder: 1,
                            },
                            {
                                words: ['4'],
                                image: 'slide_memory_sports_06_4.jpg',
                                secondOrder: 3,
                            },
                            {
                                words: ['5'],
                                image: 'slide_memory_sports_06_5.jpg',
                                secondOrder: 4,
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_sports_06_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: 'メモリースポーツに挑戦 架空の年号編',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_sports_07_1.pdf',
                    },
                    {
                        type: 'histricalDates',
                        memorizationTitle: '架空の年号を記憶してみましょう',
                        recallTitle: '架空の出来事から年号を思い出しましょう',
                        correctAnswers: [
                            {
                                words: ['1143'],
                                sentence: 'フライドチキン記念日が制定される',
                                secondOrder: 3,
                            },
                            {
                                words: ['2098'],
                                sentence: '犬が大統領になる',
                                secondOrder: 1,
                            },
                            {
                                words: ['1873'],
                                sentence: '東京の気温が45度を超える',
                                secondOrder: 4,
                            },
                            {
                                words: ['1965'],
                                sentence: 'ローストチキンが禁止される',
                                secondOrder: 2,
                            }
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_sports_07_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: 'メモリースポーツに挑戦 過去問にチャレンジ編',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_sports_08_1.pdf',
                    },
                    {
                        type: 'wordList',
                        hasMemorizationSlide: true,
                        memorizationTitle: '12個の単語を順番通り覚えましょう',
                        recallTitle: '12個の単語を思い出してみましょう',
                        numCol: 4,
                        hasFreeTextarea: false,
                        freeTextareaPlaceholder: '',
                        correctAnswers: [
                            {
                                words: [
                                    '投資家',
                                ],
                            },
                            {
                                words: [
                                    '功績',
                                ],
                            },
                            {
                                words: [
                                    '欠席',
                                ],
                            },
                            {
                                words: [
                                    'マーマレード',
                                ],
                            },
                            {
                                words: [
                                    '紳士',
                                ],
                            },
                            {
                                words: [
                                    '不当利得者',
                                ],
                            },
                            {
                                words: [
                                    '祝賀会',
                                ],
                            },
                            {
                                words: [
                                    'カニ',
                                ],
                            },
                            {
                                words: [
                                    'シカ',
                                ],
                            },
                            {
                                words: [
                                    'コスチューム',
                                ],
                            },
                            {
                                words: [
                                    '円柱',
                                ],
                            },
                            {
                                words: [
                                    'そろばん',
                                ],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_sports_08_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 9,
                lessonTitle: 'メモリースポーツの大会',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_memory_sports_09_1.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.card,
        courseTitle: 'トランプ記憶法',
        courseColor: CourseColor.yellow,
        isAdvanced: true,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: 'トランプ記憶とは？',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_card_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: 'トランプ記憶がおすすめな理由',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_card_02_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: 'トランプの覚え方',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_card_03_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: '1カード1イメージ法 ハート編',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_card_04_1.pdf',
                    },
                    {
                        type: 'cardConversionTable',
                        memorizationTitle: 'ハートの変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        correctAnswers: [
                            {
                                label: '♡A',
                                words: ['灰皿','はい皿','はいざら'],
                            },
                            {
                                label: '♡2',
                                words: ['はにわ','ハニワ','埴輪'],
                            },
                            {
                                label: '♡3',
                                words: ['はさみ','ハサミ','鋏'],
                            },
                            {
                                label: '♡4',
                                words: ['はし','ハシ','箸'],
                            },
                            {
                                label: '♡5',
                                words: ['羽子板', 'はごいた','はご板'],
                            },
                            {
                                label: '♡6',
                                words: ['ハム'],
                            },
                            {
                                label: '♡7',
                                words: ['花火','はなび','はな火'],
                            },
                            {
                                label: '♡8',
                                words: ['母','はは'],
                            },
                            {
                                label: '♡9',
                                words: ['白菜','はくさい','ハクサイ'],
                            },
                            {
                                label: '♡10',
                                words: ['鳩','はと','ハト'],
                            },
                            {
                                label: '♡J',
                                words: ['はじき','ハジキ'],
                            },
                            {
                                label: '♡Q',
                                words: ['はっぴ','法被','ハッピ'],
                            },
                            {
                                label: '♡K',
                                words: ['はぎ','萩'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_card_04_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: '1カード1イメージ法 スペード編',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_card_05_1.pdf',
                    },
                    {
                        type: 'cardConversionTable',
                        memorizationTitle: 'スペードの変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        correctAnswers: [
                            {
                                label: '♠A',
                                words: ['すいか','スイカ','西瓜'],
                            },
                            {
                                label: '♠2',
                                words: ['スニーカー'],
                            },
                            {
                                label: '♠3',
                                words: ['炭','すみ','スミ'],
                            },
                            {
                                label: '♠4',
                                words: ['寿司','すし','スシ'],
                            },
                            {
                                label: '♠5',
                                words: ['スコップ'],
                            },
                            {
                                label: '♠6',
                                words: ['スムージー'],
                            },
                            {
                                label: '♠7',
                                words: ['スナイパー'],
                            },
                            {
                                label: '♠8',
                                words: ['スーパーボール'],
                            },
                            {
                                label: '♠9',
                                words: ['スクーター'],
                            },
                            {
                                label: '♠10',
                                words: ['ストロー'],
                            },
                            {
                                label: '♠J',
                                words: ['すじこ','スジコ','筋子'],
                            },
                            {
                                label: '♠Q',
                                words: ['スピーカー'],
                            },
                            {
                                label: '♠K',
                                words: ['スキー板','スキーいた'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_card_05_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: 'トランプ記憶に挑戦①',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_card_06_1.pdf',
                    },
                    {
                        type: 'cardImages',
                        memorizationTitle: 'トランプの順番を記憶してみましょう',
                        recallTitle: 'プルダウンで記憶した順番を選択してください',
                        correctAnswers: [
                            {
                                words: ['1'],
                                image: 'slide_card_06_1.png',
                                secondOrder: 2,
                            },
                            {
                                words: ['2'],
                                image: 'slide_card_06_2.png',
                                secondOrder: 1,
                            },
                            {
                                words: ['3'],
                                image: 'slide_card_06_3.png',
                                secondOrder: 4,
                            },
                            {
                                words: ['4'],
                                image: 'slide_card_06_4.png',
                                secondOrder: 5,
                            },
                            {
                                words: ['5'],
                                image: 'slide_card_06_5.png',
                                secondOrder: 4,
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_card_06_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: '1カード1イメージ法 ダイヤ編',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_card_07_1.pdf',
                    },
                    {
                        type: 'cardConversionTable',
                        memorizationTitle: 'ダイヤの変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        correctAnswers: [
                            {
                                label: '♢A',
                                words: ['たい焼き','たいやき','たい焼','鯛焼き','鯛焼','タイヤキ'],
                            },
                            {
                                label: '♢2',
                                words: ['タニシ','たにし','田螺'],
                            },
                            {
                                label: '♢3',
                                words: ['ダンサー'],
                            },
                            {
                                label: '♢4',
                                words: ['だし巻き卵','だしまき卵','出汁巻き卵','だし巻き玉子','だしまき玉子','出汁巻き玉子','だしまきたまご'],
                            },
                            {
                                label: '♢5',
                                words: ['団子', 'だんご'],
                            },
                            {
                                label: '♢6',
                                words: ['ダム'],
                            },
                            {
                                label: '♢7',
                                words: ['棚','たな'],
                            },
                            {
                                label: '♢8',
                                words: ['タッパー'],
                            },
                            {
                                label: '♢9',
                                words: ['たくあん','タクアン','沢庵','沢あん'],
                            },
                            {
                                label: '♢10',
                                words: ['タンドリーチキン'],
                            },
                            {
                                label: '♢J',
                                words: ['誕生日ケーキ'],
                            },
                            {
                                label: '♢Q',
                                words: ['タピオカ'],
                            },
                            {
                                label: '♢K',
                                words: ['滝','たき'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_card_07_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: '1カード1イメージ法 クローバー編',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_card_08_1.pdf',
                    },
                    {
                        type: 'cardConversionTable',
                        memorizationTitle: 'クローバーの変換表を覚えてみましょう',
                        recallTitle: 'イメージを記入しましょう',
                        correctAnswers: [
                            {
                                label: '♣A',
                                words: ['クイズ'],
                            },
                            {
                                label: '♣2',
                                words: ['国','くに','クニ'],
                            },
                            {
                                label: '♣3',
                                words: ['草','くさ'],
                            },
                            {
                                label: '♣4',
                                words: ['串カツ','くしカツ','クシカツ'],
                            },
                            {
                                label: '♣5',
                                words: ['空港','くうこう'],
                            },
                            {
                                label: '♣6',
                                words: ['黒豆','くろ豆','くろまめ'],
                            },
                            {
                                label: '♣7',
                                words: ['くない','クナイ','苦無'],
                            },
                            {
                                label: '♣8',
                                words: ['クッパ'],
                            },
                            {
                                label: '♣9',
                                words: ['九九','くく'],
                            },
                            {
                                label: '♣10',
                                words: ['句読点'],
                            },
                            {
                                label: '♣J',
                                words: ['くじ','クジ','籤'],
                            },
                            {
                                label: '♣Q',
                                words: ['グッピー'],
                            },
                            {
                                label: '♣K',
                                words: ['くぎ','釘'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_card_08_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 9,
                lessonTitle: 'トランプ記憶に挑戦②',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_card_09_1.pdf',
                    },
                    {
                        type: 'cardImages',
                        memorizationTitle: 'トランプの順番を記憶してみましょう',
                        recallTitle: 'プルダウンで記憶した順番を選択してください',
                        correctAnswers: [
                            {
                                words: ['1'],
                                image: 'slide_card_09_1.png',
                                secondOrder: 9,
                            },
                            {
                                words: ['2'],
                                image: 'slide_card_09_2.png',
                                secondOrder: 8,
                            },
                            {
                                words: ['3'],
                                image: 'slide_card_09_3.png',
                                secondOrder: 3,
                            },
                            {
                                words: ['4'],
                                image: 'slide_card_09_4.png',
                                secondOrder: 6,
                            },
                            {
                                words: ['5'],
                                image: 'slide_card_09_5.png',
                                secondOrder: 10,
                            },
                            {
                                words: ['6'],
                                image: 'slide_card_09_6.png',
                                secondOrder: 1,
                            },
                            {
                                words: ['7'],
                                image: 'slide_card_09_7.png',
                                secondOrder: 4,
                            },
                            {
                                words: ['8'],
                                image: 'slide_card_09_8.png',
                                secondOrder: 7,
                            },
                            {
                                words: ['9'],
                                image: 'slide_card_09_9.png',
                                secondOrder: 2,
                            },
                            {
                                words: ['10'],
                                image: 'slide_card_09_10.png',
                                secondOrder: 5,
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_card_09_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 10,
                lessonTitle: 'トランプ記憶の練習方法',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_card_10_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 11,
                lessonTitle: 'トランプ記憶を披露する',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_card_11_1.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.sdgs,
        courseTitle: 'SDGs記憶',
        courseColor: CourseColor.yellow,
        isAdvanced: true,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: '戦略を立てる',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_sdgs_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: '使う記憶術を決める',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_sdgs_02_1.pdf',
                    },
                    {
                        type: 'sdgsConversionTable',
                        memorizationTitle: 'ペグで使う数字のイメージを復習しましょう',
                        recallTitle: '覚えたイメージを記入しましょう',
                        correctAnswers: [
                            {
                                label: '01',
                                words: ['ワイン'],
                            },
                            {
                                label: '02',
                                words: ['ワカメ','わかめ'],
                            },
                            {
                                label: '03',
                                words: ['お酢','酢','す'],
                            },
                            {
                                label: '04',
                                words: ['わたあめ','綿あめ','綿飴'],
                            },
                            {
                                label: '05',
                                words: ['鬼','オニ','おに'],
                            },
                            {
                                label: '06',
                                words: ['お箸','おはし','箸','はし'],
                            },
                            {
                                label: '07',
                                words: ['おみくじ','お神籤','御神籤'],
                            },
                            {
                                label: '08',
                                words: ['お湯','おゆ','湯','ゆ'],
                            },
                            {
                                label: '09',
                                words: ['折り紙','折紙','おり紙','おりがみ','オリガミ'],
                            },
                            {
                                label: '10',
                                words: ['岩','いわ'],
                            },
                            {
                                label: '11',
                                words: ['アイロン'],
                            },
                            {
                                label: '12',
                                words: ['赤ちゃん','あかちゃん','アカチャン'],
                            },
                            {
                                label: '13',
                                words: ['足','脚','あし'],
                            },
                            {
                                label: '14',
                                words: ['板','いた'],
                            },
                            {
                                label: '15',
                                words: ['イナゴ','蝗','いなご'],
                            },
                            {
                                label: '16',
                                words: ['アヒル','家鴨','あひる'],
                            },
                            {
                                label: '17',
                                words: ['網','あみ'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_sdgs_02_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: '17項目のキーワードを抜き出す',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_sdgs_03_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: 'ペグと結び付けて覚える',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_sdgs_04_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: 'キーワードを思い出す(前編)',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_sdgs_05_1.pdf',
                    },
                    {
                        type: 'sdgsKeyword',
                        memorizationTitle: 'SDGsの各項目のキーワードを覚えましょう',
                        recallTitle: 'ペグから各項目のキーワードを思い出しましょう',
                        has6Rows: false,
                        correctAnswers: [
                          {
                              label: '01',
                              words: ['貧困', 'ひんこん'],
                          },
                          {
                              label: '02',
                              words: ['飢餓','きが'],
                          },
                          {
                              label: '03',
                              words: ['健康', 'けんこう'],
                          },
                          {
                              label: '04',
                              words: ['教育', 'きょういく'],
                          },
                          {
                              label: '05',
                              words: ['ジェンダー'],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          {
                              words: ['福祉','ふくし'],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_sdgs_05_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: 'キーワードを思い出す(中編)',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_sdgs_06_1.pdf',
                    },
                    {
                        type: 'sdgsKeyword',
                        memorizationTitle: 'SDGsの各項目のキーワードを覚えましょう',
                        recallTitle: 'ペグから各項目のキーワードを思い出しましょう',
                        has6Rows: true,
                        correctAnswers: [
                          {
                              label: '06',
                              words: ['水', 'みず'],
                          },
                          {
                              label: '07',
                              words: ['エネルギー'],
                          },
                          {
                              label: '08',
                              words: ['働きがい', '働き甲斐', 'はたらき甲斐', 'はたらきがい'],
                          },
                          {
                              label: '09',
                              words: ['産業','さんぎょう'],
                          },
                          {
                              label: '10',
                              words: ['人', 'ひと', 'ヒト'],
                          },
                          {
                              label: '11',
                              words: ['まちづくり', '街づくり', '街作り', '町づくり', '町作り'],
                          },
                          {
                              words: ['トイレ'],
                          },
                          {
                              words: ['クリーン'],
                          },
                          {
                              words: ['経済成長','けいざいせいちょう'],
                          },
                          {
                              words: ['技術革新','ぎじゅつかくしん'],
                          },
                          {
                              words: ['国', 'くに'],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_sdgs_06_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: 'キーワードを思い出す(後編)',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_sdgs_07_1.pdf',
                    },
                    {
                        type: 'sdgsKeyword',
                        memorizationTitle: 'SDGsの各項目のキーワードを覚えましょう',
                        recallTitle: 'ペグから各項目のキーワードを思い出しましょう',
                        has6Rows: true,
                        correctAnswers: [
                          {
                              label: '12',
                              words: ['つくる', '作る'],
                          },
                          {
                              label: '13',
                              words: ['気候変動'],
                          },
                          {
                              label: '14',
                              words: ['海', 'うみ'],
                          },
                          {
                              label: '15',
                              words: ['陸', 'りく'],
                          },
                          {
                              label: '16',
                              words: ['平和', 'へいわ'],
                          },
                          {
                              label: '17',
                              words: ['パートナーシップ'],
                          },
                          {
                              words: ['責任','せきにん'],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [''],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [''],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [''],
                          },
                          {
                              words: ['公正', 'こうせい'],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          {
                              words: ['つかう','使う','遣う'],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                          { // スペーシングのためのダミーの解答
                              words: [],
                          },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_sdgs_07_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: '次のステップを考える',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_sdgs_08_1.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.worldHeritage,
        courseTitle: '世界遺産記憶',
        courseColor: CourseColor.yellow,
        isAdvanced: true,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: '世界遺産とは',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_world_heritage_01_1.pdf',
                    },
                    {
                        type: 'fillInTheBlank',
                        memorizationTitle: '文化遺産と自然遺産の登録基準のキーワードを覚えましょう',
                        recallTitle: '文化遺産と自然遺産の登録基準のキーワードを思い出しましょう',
                        correctAnswers: [
                            {
                                label: '1.',
                                sentence: '人類の',
                                words: ['創造的資質'],
                                sentence2: 'を示す傑作である。',
                            },
                            {
                                label: '2.',
                                sentence: '重要な',
                                words: ['価値観の交流'],
                                sentence2: 'を示すものである。',
                            },
                            {
                                label: '6.',
                                sentence: '顕著な',
                                words: ['普遍的価値'],
                                sentence2: 'を持つ出来事と関連がある。',
                            },
                            {
                                label: '7.',
                                sentence: '優れた自然美や美的重要性を持つ、類まれな',
                                words: ['自然現象'],
                                sentence2: 'や地域である。',
                            },
                            {
                                label: '8.',
                                sentence: '',
                                words: ['生命の進化'],
                                sentence2: 'や地球形成の歴史の主要段階を示す顕著な見本である。',
                            },
                            {
                                label: '10.',
                                sentence: '',
                                words: ['絶滅'],
                                sentence2: 'の恐れがあり、顕著な普遍的価値を持つ野生種の生息域を含む、生物多様性の保全のために最も重要かつ代表的な自然生息域である。',
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_world_heritage_01_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: '写真と名前を覚える',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_world_heritage_02_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: '練習問題①',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_world_heritage_03_1.pdf',
                    },
                    {
                        type: 'imagesWithSentence',
                        memorizationTitle: '写真の世界遺産の名称を覚えましょう',
                        recallTitle: '写真の世界遺産の名称を記入してください',
                        correctAnswers: [
                            {
                                words: ['古都ルアン・パバン'],
                                image: 'slide_world_heritage_03_1.jpg',
                                sentence: '(ラオス)',
                                secondOrder: 3,
                            },
                            {
                                words: ['知床'],
                                image: 'slide_world_heritage_03_2.jpg',
                                sentence: '(北海道)',
                                secondOrder: 1,
                            },
                            {
                                words: ['聖都カイラワーン'],
                                image: 'slide_world_heritage_03_3.jpg',
                                sentence: '(チュニジア)',
                                secondOrder: 2,
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_world_heritage_03_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: '背景知識を覚える',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_world_heritage_04_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: '練習問題②',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_world_heritage_05_1.pdf',
                    },
                    {
                        type: 'fillInTheBlankArticle',
                        memorizationTitle: '背景知識の赤字のキーワードを覚えましょう',
                        recallTitle: '背景知識の空欄を埋めてください',
                        correctAnswers: [
                            {
                                image: 'slide_world_heritage_05_1.jpg',
                                sentence: 'この遺産は、',
                                words: ['アテネ'],
                                sentence2: 'の',
                            },
                            {
                                words: ['アクロポリス'],
                                sentence2: 'といい、',
                            },
                            {
                                words: ['ギリシャ'],
                                sentence2: 'にある。',
                            },
                            {
                                sentence: 'この地域は戦いと知恵の',
                                words: ['女神アテナ'],
                                sentence2: 'に由来する名を持ち、',
                            },
                            {
                                words: ['高い丘の上の都市'],
                                sentence2: 'を意味する。\n',
                            },
                            {
                                sentence: '一番有名なのは',
                                words: ['ドリス式建築'],
                                sentence2: 'の傑作と言われる',
                            },
                            {
                                words: ['パルテノン神殿'],
                                sentence2: 'というものである。',
                            },
                            {
                                sentence: 'これらの建築物は、その後の世界史に大きく影響を及ぼした',
                                words: ['ギリシャ文化'],
                                sentence2: 'の中心地としてその地位をゆるぎないものにしていった。',
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_world_heritage_05_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: '国別登録数ランキング',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_world_heritage_06_1.pdf',
                    },
                    {
                        type: 'rankTable',
                        memorizationTitle: '世界遺産国別登録数ランキング1位から10位を覚えましょう',
                        recallTitle: '回答を記入してください',
                        correctAnswers: [
                            {
                                label: '1位',
                                words: ['イタリア'],
                            },
                            {
                                label: '件',
                                words: ['58','５８'], // IGNORE_TERM_CONSISTENCY_RULES
                            },
                            {
                                label: '2位',
                                words: ['中国'],
                            },
                            {
                                label: '件',
                                words: ['56','５６'], // IGNORE_TERM_CONSISTENCY_RULES
                            },
                            {
                                label: '3位',
                                words: ['ドイツ'],
                            },
                            {
                                label: '件',
                                words: ['51','５１'], // IGNORE_TERM_CONSISTENCY_RULES
                            },
                            {
                                label: '4位',
                                words: ['スペイン'],
                            },
                            {
                                label: '件',
                                words: ['49','４９'], // IGNORE_TERM_CONSISTENCY_RULES
                            },
                            {
                                label: '4位',
                                words: ['フランス'],
                            },
                            {
                                label: '件',
                                words: ['49','４９'], // IGNORE_TERM_CONSISTENCY_RULES
                            },
                            {
                                label: '6位',
                                words: ['インド'],
                            },
                            {
                                label: '件',
                                words: ['40','４０'], // IGNORE_TERM_CONSISTENCY_RULES
                            },
                            {
                                label: '7位',
                                words: ['メキシコ'],
                            },
                            {
                                label: '件',
                                words: ['35','３５'], // IGNORE_TERM_CONSISTENCY_RULES
                            },
                            {
                                label: '8位',
                                words: ['イギリス'],
                            },
                            {
                                label: '件',
                                words: ['33','３３'], // IGNORE_TERM_CONSISTENCY_RULES
                            },
                            {
                                label: '9位',
                                words: ['ロシア'],
                            },
                            {
                                label: '件',
                                words: ['30','３０'], // IGNORE_TERM_CONSISTENCY_RULES
                            },
                            {
                                label: '10位',
                                words: ['イラン'],
                            },
                            {
                                label: '件',
                                words: ['26','２６'], // IGNORE_TERM_CONSISTENCY_RULES
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_world_heritage_06_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: '世界一長い名前の遺産',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_world_heritage_07_1.pdf',
                    },
                    {
                        type: 'sentence',
                        memorizationTitle: '改めて世界一長い世界遺産の名前を覚えてください',
                        recallTitle: '覚えた名前を記入してください',
                        correctAnswers: [
                            {
                                label: '①',
                                words: ['グラアニーのイエズス会伝道所群'],
                            },
                            {
                                label: '②',
                                words: ['サン・イグナシオ・ミニ','サン・イグナシオミニ','サンイグナシオ・ミニ','サンイグナシオミニ'],
                            },
                            {
                                label: '③',
                                words: ['サンタ・アナ','サンタアナ'],
                            },
                            {
                                label: '④',
                                words: ['ヌエストラ・セニョーラ・デ・ロレート','ヌエストラ・セニョーラ・デロレート',
                                        'ヌエストラ・セニョーラデ・ロレート','ヌエストラセニョーラ・デ・ロレート',
                                        'ヌエストラ・セニョーラデロレート','ヌエストラセニョーラデ・ロレート',
                                        'ヌエストラセニョーラ・デロレート','ヌエストラセニョーラデロレート'],
                            },
                            {
                                label: '⑤',
                                words: ['サンタ・マリア・マジョール','サンタ・マリアマジョール','サンタマリア・マジョール','サンタマリアマジョール'],
                            },
                            {
                                label: '⑥',
                                words: ['サン・ミゲル・ダス・ミソンイス遺跡','サン・ミゲル・ダスミソンイス遺跡',
                                        'サン・ミゲルダス・ミソンイス遺跡','サンミゲル・ダス・ミソンイス遺跡',
                                        'サン・ミゲルダスミソンイス遺跡','サンミゲル・ダスミソンイス遺跡',
                                        'サンミゲルダス・ミソンイス遺跡','サンミゲルダスミソンイス遺跡'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_world_heritage_07_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: '総仕上げ',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_world_heritage_08_1.pdf',
                    },
                    {
                        type: 'manyImages',
                        memorizationTitle: '写真の世界遺産の名称を覚えましょう',
                        recallTitle: '写真の世界遺産の名称を記入してください',
                        correctAnswers: [
                            {
                                words: ['カステル・デル・モンテ','カステル・デルモンテ','カステルデル・モンテ','カステルデルモンテ'],
                                image: 'slide_world_heritage_08_1.jpg',
                                secondOrder: 7,
                            },
                            {
                                words: ['アブシンベル神殿'],
                                image: 'slide_world_heritage_08_2.jpg',
                                secondOrder: 6,
                            },
                            {
                                words: ['ンゴロンゴロ保全地域'],
                                image: 'slide_world_heritage_08_3.jpg',
                                secondOrder: 4,
                            },
                            {
                                words: ['日光の社寺'],
                                image: 'slide_world_heritage_08_4.jpg',
                                secondOrder: 5,
                            },
                            {
                                words: ['ティワナク'],
                                image: 'slide_world_heritage_08_5.jpg',
                                secondOrder: 8,
                            },
                            {
                                words: ['ポン・デュ・ガール','ポン・デュガール','ポンデュ・ガール','ポンデュガール'],
                                image: 'slide_world_heritage_08_6.jpg',
                                secondOrder: 2,
                            },
                            {
                                words: ['ベルゲンのブリッゲン地区'],
                                image: 'slide_world_heritage_08_7.jpg',
                                secondOrder: 1,
                            },
                            {
                                words: ['カナイマ国立公園'],
                                image: 'slide_world_heritage_08_8.jpg',
                                secondOrder: 3,
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_world_heritage_08_2.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.advancedPegMethod,
        courseTitle: 'ペグ法応用編',
        courseColor: CourseColor.yellow,
        isAdvanced: true,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: 'ペグ法の復習',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_advanced_peg_method_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: '都道府県でペグを作る 北海道・東北編',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_advanced_peg_method_02_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '北海道・東北の各道県のイメージを覚えましょう',
                        recallTitle: '覚えたイメージを記入しましょう',
                        resultTitle: '結果を判定します',
                        correctAnswers: [
                            {
                                label: '北海道',
                                words: ['ウニ'],
                            },
                            {
                                label: '青森',
                                words: ['りんご'],
                            },
                            {
                                label: '秋田',
                                words: ['きりたんぽ'],
                            },
                            {
                                label: '岩手',
                                words: ['わんこそば'],
                            },
                            {
                                label: '山形',
                                words: ['さくらんぼ'],
                            },
                            {
                                label: '宮城',
                                words: ['牛タン'],
                            },
                            {
                                label: '福島',
                                words: ['もも'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_advanced_peg_method_02_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: '都道府県のペグを使って記憶してみる 北海道・東北編',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_advanced_peg_method_03_1.pdf',
                    },
                    {
                        type: 'wordList',
                        hasMemorizationSlide: true,
                        memorizationTitle: '11個の単語をペグ法で覚えましょう',
                        recallTitle: '11個の単語を思い出してみましょう',
                        resultTitle: '結果を判定します',
                        freeTextareaPlaceholder: '作ったストーリーを思い出してみましょう',
                        numCol: 4,
                        hasFreeTextarea: true,
                        correctAnswers: [
                            {
                                words: ['ネオンサイン'],
                            },
                            {
                                words: ['塩'],
                            },
                            {
                                words: ['にがり'],
                            },
                            {
                                words: ['1円玉','１円玉'], // IGNORE_TERM_CONSISTENCY_RULES
                            },
                            {
                                words: ['珪藻土マット'],
                            },
                            {
                                words: ['マッチ'],
                            },
                            {
                                words: ['温泉'],
                            },
                            {
                                words: ['消毒液'],
                            },
                            {
                                words: ['窓のサッシ'],
                            },
                            {
                                words: ['ワカメ'],
                            },
                            {
                                words: ['牛乳'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_advanced_peg_method_03_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: '都道府県のペグを使って思い出してみる 北海道・東北編',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_advanced_peg_method_04_1.pdf',
                    },
                    {
                        type: 'keywords',
                        memorizationTitle: '九州の各県のイメージを覚えましょう',
                        recallTitle: 'イメージから元の単語を思い出しましょう',
                        resultTitle: '結果を判定します',
                        hasMemorizationSlide: false,
                        has6Rows: false,
                        correctAnswers: [
                            {
                                label: '岩手(わんこそば)',
                                words: ['ネオン'],
                                placeHolder: 'ネオンサイン',
                            },
                            {
                                label: '山形(さくらんぼ)',
                                words: ['アルミニウム'],
                                placeHolder: '1円玉',
                            },
                            {
                                label: '宮城(牛タン)',
                                words: ['硫黄'],
                                placeHolder: '温泉',
                            },
                            {
                                label: '福島(もも)',
                                words: ['カリウム'],
                                placeHolder: 'ワカメ',
                            },
                            { // スペーシングのためのダミーの解答
                                label: '',
                                words: [],
                                placeHolder: '',
                            },
                            {
                                label: '',
                                words: ['ナトリウム'],
                                placeHolder: '塩',
                            },
                            {
                                label: '',
                                words: ['珪藻土マット'],
                                placeHolder: 'ケイ素',
                            },
                            {
                                label: '',
                                words: ['消毒液'],
                                placeHolder: '塩素',
                            },
                            {
                                label: '',
                                words: ['牛乳'],
                                placeHolder: 'カルシウム',
                            },
                            { // スペーシングのためのダミーの解答
                                label: '',
                                words: [''],
                                placeHolder: '',
                            },
                            {
                                label: '',
                                words: ['マグネシウム'],
                                placeHolder: 'にがり',
                            },
                            {
                                label: '',
                                words: ['リン'],
                                placeHolder: 'マッチ',
                            },
                            {
                                label: '',
                                words: ['アルゴン'],
                                placeHolder: '窓のサッシ',
                            },
                            { // スペーシングのためのダミーの解答
                                label: '',
                                words: [''],
                                placeHolder: '',
                            },
                            { // スペーシングのためのダミーの解答
                                label: '',
                                words: [''],
                                placeHolder: '',
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_advanced_peg_method_04_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: 'ペグを増やしてみる 九州編',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_advanced_peg_method_05_1.pdf',
                    },
                    {
                        type: 'conversionTable',
                        memorizationTitle: '九州の各県のイメージを覚えましょう',
                        recallTitle: '覚えたイメージを記入しましょう',
                        resultTitle: '結果を判定します',
                        correctAnswers: [
                            {
                                label: '福岡',
                                words: ['明太子'],
                            },
                            {
                                label: '佐賀',
                                words: ['佐賀牛'],
                            },
                            {
                                label: '長崎',
                                words: ['カステラ'],
                            },
                            {
                                label: '大分',
                                words: ['とり天'],
                            },
                            {
                                label: '熊本',
                                words: ['馬刺し'],
                            },
                            {
                                label: '宮崎',
                                words: ['マンゴー'],
                            },
                            {
                                label: '鹿児島',
                                words: ['黒豚'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_advanced_peg_method_05_2.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: 'ペグとして使えるもの',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_advanced_peg_method_06_1.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.circleRatio,
        courseTitle: '円周率記憶法',
        courseColor: CourseColor.yellow,
        isAdvanced: true,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: '円周率を覚える',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_circle_ratio_01_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 2,
                lessonTitle: '20桁記憶に挑戦',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_circle_ratio_02_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: '50桁記憶に挑戦①',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_circle_ratio_03_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: '50桁記憶に挑戦②',
                isFree: true,
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_circle_ratio_04_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: '100桁記憶に挑戦①',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_circle_ratio_05_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: '100桁記憶に挑戦②',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_circle_ratio_06_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: '100桁記憶に挑戦③',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_circle_ratio_07_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: '適切な復習頻度',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_circle_ratio_08_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 9,
                lessonTitle: 'さらなる桁数を目指して',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_circle_ratio_09_1.pdf',
                    },
                ],
            },
        ],
    },
    {
        courseId: CourseId.hangul,
        courseTitle: 'ハングル記憶法',
        courseColor: CourseColor.yellow,
        isAdvanced: true,
        lessons: [
            {
                lessonNo: 1,
                lessonTitle: 'ハングルを覚える',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_01_1.pdf',
                    },
                ],
            },           
            {
                lessonNo: 2,
                lessonTitle: 'ハングルの母音を覚える①',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_02_1.pdf',
                    },
                    {
                        type: 'hangul',
                        memorizationTitle: 'ハングルの読み方を覚えてみましょう',
                        recallTitle: 'ハングルの読み方を選んでみましょう',
                        hasMemorizationSlide: true,
                        correctAnswers: [
                            {
                                label: '아',
                                words: ['a(あ)'],
                                options: ['eo(お)', 'ya(や)', 'yeo(よ)', 'a(あ)'],
                            },
                            {
                                label: '야',
                                words: ['ya(や)'],
                                options: ['ya(や)', 'a(あ)', 'yeo(よ)', 'eo(お)'],
                            },
                            {
                                label: '어',
                                words: ['eo(お)'],
                                options: ['ya(や)', 'yeo(よ)', 'eo(お)', 'a(あ)'],
                            },
                            {
                                label: '여',
                                words: ['yeo(よ)'],
                                options: ['eo(お)', 'a(あ)', 'yeo(よ)', 'ya(や)'],
                            },
                            {
                                label: '오',
                                words: ['o(お)'],
                                options: ['o(お)', 'yo(よ)', 'yu(ゆ)', 'u(う)'],
                            },
                            {
                                label: '요',
                                words: ['yo(よ)'],
                                options: ['yu(ゆ)', 'o(お)', 'yo(よ)', 'u(う)'],
                            },
                            {
                                label: '우',
                                words: ['u(う)'],
                                options: ['u(う)', 'o(お)', 'yo(よ)', 'yu(ゆ)'],
                            },
                            {
                                label: '유',
                                words: ['yu(ゆ)'],
                                options: ['o(お)', 'yu(ゆ)', 'yo(よ)', 'u(う)'],
                            },
                            {
                                label: '으',
                                words: ['eu(う)'],
                                options: ['eu(う)', 'o(お)', 'u(う)', 'yo(よ)'],
                            },
                            {
                                label: '이',
                                words: ['i(い)'],
                                options: ['i(い)', 'eu(う)', 'yo(よ)', 'u(う)'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_02_2.pdf',
                    },                    
                ],
            },
            {
                lessonNo: 3,
                lessonTitle: 'ハングルの母音を覚える②',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_03_1.pdf',
                    },
                    {
                        type: 'hangul',
                        memorizationTitle: 'ハングルの読み方を覚えてみましょう',
                        recallTitle: 'ハングルの読み方を選んでみましょう',
                        hasMemorizationSlide: true,
                        correctAnswers: [
                            {
                                label: '애',
                                words: ['ae(え)'],
                                options: ['yae(いぇ)', 'wo(うぉ)', 'ae(え)', 'wa(わ)'],
                            },
                            {
                                label: '얘',
                                words: ['yae(いぇ)'],
                                options: ['yae(いぇ)', 'ae(え)', 'wa(わ)', 'wo(うぉ)'],
                            },
                            {
                                label: '에',
                                words: ['e(え)'],
                                options: ['wa(わ)', 'e(え)', 'ye(いぇ)', 'wo(うぉ)'],
                            },
                            {
                                label: '예',
                                words: ['ye(いぇ)'],
                                options: ['e(え)', 'wo(うぉ)', 'wa(わ)', 'ye(いぇ)'],
                            },
                            {
                                label: '와',
                                words: ['wa(わ)'],
                                options: ['wa(わ)', 'wo(うぉ)', 'we(うぇ)', 'wi(うぃ)'],
                            },
                            {
                                label: '워',
                                words: ['wo(うぉ)'],
                                options: ['wa(わ)', 'we(うぇ)', 'wo(うぉ)', 'wi(うぃ)'],
                            },
                            {
                                label: '위',
                                words: ['wi(うぃ)'],
                                options: ['wi(うぃ)', 'we(うぇ)', 'wo(うぉ)', 'wa(わ)'],
                            },
                            {
                                label: '의',
                                words: ['ui(うぃ)'],
                                options: ['ui(うぃ)', 'we(うぇ)', 'wa(わ)', 'wo(うぉ)'],
                            },
                            {
                                label: '웨',
                                words: ['we(うぇ)'],
                                options: ['wo(うぉ)', 'ui(うぃ)', 'wa(わ)', 'we(うぇ)'],
                            },
                            {
                                label: '왜',
                                words: ['wae(うぇ)'],
                                options: ['wo(うぉ)', 'ui(うぃ)', 'wa(わ)', 'wae(うぇ)'],
                            },
                            {
                                label: '외',
                                words: ['oe(うぇ)'],
                                options: ['wo(うぉ)', 'ui(うぃ)', 'oe(うぇ)', 'wa(わ)'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_03_2.pdf',
                    },    
                ],
            },
            {
                lessonNo: 4,
                lessonTitle: 'ハングルの子音を覚える①',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_04_1.pdf',
                    },
                    {
                        type: 'hangul',
                        memorizationTitle: 'ハングルの読み方を覚えてみましょう',
                        recallTitle: 'ハングルの読み方を選んでみましょう',
                        hasMemorizationSlide: true,
                        correctAnswers: [
                            {
                                label: '가',
                                words: ['ka(か)'],
                                options: ['ra(ら)', 'ka(か)', 'ta(た)', 'na(な)'],
                            },
                            {
                                label: '나',
                                words: ['na(な)'],
                                options: ['na(な)', 'ra(ら)', 'ka(か)', 'ta(た)'],
                            },
                            {
                                label: '다',
                                words: ['ta(た)'],
                                options: ['na(な)', 'ka(か)', 'ta(た)', 'ra(ら)'],
                            },
                            {
                                label: '라',
                                words: ['ra(ら)'],
                                options: ['ra(ら)', 'ka(か)', 'na(な)', 'ta(た)'],
                            },
                            {
                                label: '마',
                                words: ['ma(ま)'],
                                options: ['sa(さ)', 'ra(ら)', 'ma(ま)', 'pa(ぱ)'],
                            },
                            {
                                label: '바',
                                words: ['pa(ぱ)'],
                                options: ['sa(さ)', 'ma(ま)', 'pa(ぱ)', 'ra(ら)'],
                            },
                            {
                                label: '사',
                                words: ['sa(さ)'],
                                options: ['cha(ちゃ)', 'ha(は)', 'a(あ)', 'sa(さ)'],
                            },
                            {
                                label: '아',
                                words: ['a(あ)'],
                                options: ['a(あ)', 'ha(は)', 'cha(ちゃ)', 'sa(さ)'],
                            },
                            {
                                label: '자',
                                words: ['cha(ちゃ)'],
                                options: ['ha(は)', 'sa(さ)', 'a(あ)', 'cha(ちゃ)'],
                            },
                            {
                                label: '하',
                                words: ['ha(は)'],
                                options: ['sa(さ)', 'a(あ)', 'cha(ちゃ)', 'ha(は)'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_04_2.pdf',
                    },    
                ],
            },
            {
                lessonNo: 5,
                lessonTitle: 'ハングルの子音を覚える②',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_05_1.pdf',
                    },
                    {
                        type: 'hangul',
                        memorizationTitle: 'ハングルの読み方を覚えてみましょう',
                        recallTitle: 'ハングルの読み方を選んでみましょう',
                        hasMemorizationSlide: true,
                        correctAnswers: [
                            {
                                label: '카',
                                words: ['ka(か)'],
                                options: ['ka(か)', 'cha(ちゃ)', 'pa(ぱ)', 'ta(た)'],
                            },
                            {
                                label: '파',
                                words: ['pa(ぱ)'],
                                options: ['cha(ちゃ)', 'ta(た)', 'pa(ぱ)', 'ka(か)'],
                            },
                            {
                                label: '타',
                                words: ['ta(た)'],
                                options: ['ta(た)', 'ka(か)', 'cha(ちゃ)', 'pa(ぱ)'],
                            },
                            {
                                label: '차',
                                words: ['cha(ちゃ)'],
                                options: ['pa(ぱ)', 'ta(た)', 'ka(か)', 'cha(ちゃ)'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_05_2.pdf',
                    },    
                ],
            },
            {
                lessonNo: 6,
                lessonTitle: 'ハングルの子音を覚える③',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_06_1.pdf',
                    },
                    {
                        type: 'hangul',
                        memorizationTitle: 'ハングルの読み方を覚えてみましょう',
                        recallTitle: 'ハングルの読み方を選んでみましょう',
                        hasMemorizationSlide: true,
                        correctAnswers: [
                            {
                                label: '까',
                                words: ['kka(か)'],
                                options: ['ppa(ぱ)', 'kka(か)', 'tta(た)', 'tcha(ちゃ)'],
                            },
                            {
                                label: '빠',
                                words: ['ppa(ぱ)'],
                                options: ['ppa(ぱ)', 'kka(か)', 'tta(た)', 'tcha(ちゃ)'],
                            },
                            {
                                label: '짜',
                                words: ['tcha(ちゃ)'],
                                options: ['ppa(ぱ)', 'kka(か)', 'tta(た)', 'tcha(ちゃ)'],
                            },
                            {
                                label: '따',
                                words: ['tta(た)'],
                                options: ['tta(た)', 'kka(か)', 'ppa(ぱ)', 'tcha(ちゃ)'],
                            },
                            {
                                label: '싸',
                                words: ['ssa(さ)'],
                                options: ['ssa(さ)', 'tta(た)', 'ppa(ぱ)', 'tcha(ちゃ)'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_06_2.pdf',
                    },    
                ],
            },
            {
                lessonNo: 7,
                lessonTitle: 'パッチムを覚える①',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_07_1.pdf',
                    },
                    {
                        type: 'hangul',
                        memorizationTitle: 'ハングルの読み方を覚えてみましょう',
                        recallTitle: 'ハングルの読み方を選んでみましょう',
                        hasMemorizationSlide: false,
                        correctAnswers: [
                            {
                                label: '학',
                                words: ['はく'],
                                options: ['ぱっぷ', 'よん', 'なっ', 'はく'],
                            },
                            {
                                label: '밥',
                                words: ['ぱっぷ'],
                                options: ['ぱっぷ', 'はく', 'よん', 'なっ'],
                            },
                            {
                                label: '낫',
                                words: ['なっ'],
                                options: ['はく', 'なっ', 'よん', 'ぱっぷ'],
                            },
                            {
                                label: '낮',
                                words: ['なっ'],
                                options: ['なっ', 'はく', 'よん', 'ぱっぷ'],
                            },
                            {
                                label: '영',
                                words: ['よん'],
                                options: ['なっ', 'よん', 'はく', 'ぱっぷ'],
                            },
                            {
                                label: '밤',
                                words: ['ぱむ'],
                                options: ['ぱっぷ', 'ぱむ', 'かん', 'たる'],
                            },
                            {
                                label: '간',
                                words: ['かん'],
                                options: ['ぱむ', 'かん', 'たる', 'ぱっぷ'],
                            },
                            {
                                label: '딸',
                                words: ['たる'],
                                options: ['かん', 'たる', 'ぱむ', 'ぱっぷ'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_07_2.pdf',
                    },    
                ],
            },
            {
                lessonNo: 8,
                lessonTitle: 'パッチムを覚える②',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_08_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 9,
                lessonTitle: 'パッチムを覚える③',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_09_1.pdf',
                    },
                ],
            },
            {
                lessonNo: 10,
                lessonTitle: 'ハングルを実際に読んでみる',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_10_1.pdf',
                    },
                    {
                        type: 'hangul',
                        memorizationTitle: '',
                        recallTitle: 'ハングルの読み方を選んでみましょう',
                        hasMemorizationSlide: false,
                        correctAnswers: [
                            {
                                label: '학(学)',
                                words: ['はく'],
                                options: ['よん', 'ぱっぷ', 'はく', 'なっ'],
                            },
                            {
                                label: '밥(ご飯)',
                                words: ['ぱっぷ'],
                                options: ['よん', 'ぱっぷ', 'なっ', 'はく'],
                            },
                            {
                                label: '낫(鎌)',
                                words: ['なっ'],
                                options: ['よん', 'はく', 'ぱっぷ', 'なっ'],
                            },
                            {
                                label: '낮(昼)',
                                words: ['なっ'],
                                options: ['はく', 'よん', 'ぱっぷ', 'なっ'],
                            },
                            {
                                label: '영(英)',
                                words: ['よん'],
                                options: ['よん', 'ぱっぷ', 'なっ', 'はく'],
                            },
                            {
                                label: '밤(夜)',
                                words: ['ぱむ'],
                                options: ['さの', 'ぱむ', 'たる', 'ぱっぷ'],
                            },
                            {
                                label: '딸(娘)',
                                words: ['たる'],
                                options: ['ぱむ', 'さの', 'ぱっぷ', 'たる'],
                            },
                            {
                                label: '상어(鮫)',
                                words: ['さの'],
                                options: ['たる', 'ぱっぷ', 'ぱむ', 'さの'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_10_2.pdf',
                    },                    
                ],
            }, 
            {
                lessonNo: 11,
                lessonTitle: 'ハングルを書いてみる',
                slides: [
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_11_1.pdf',
                    },
                    {
                        type: 'hangul',
                        memorizationTitle: '',
                        recallTitle: 'ハングルを選んでみましょう',
                        hasMemorizationSlide: false,
                        correctAnswers: [
                            {
                                label: '学(ハク)',
                                words: ['학'],
                                options: ['낫', '학', '낮', '밥'],
                            },
                            {
                                label: 'ご飯(パップ)',
                                words: ['밥'],
                                options: ['학', '밥', '낮', '낫'],
                            },
                            {
                                label: '鎌(ナッ)',
                                words: ['낫'],
                                options: ['낮', '학', '밥', '낫'],
                            },
                            {
                                label: '昼(ナッ)',
                                words: ['낮'],
                                options: ['밥', '낮', '학', '낫'],
                            },
                            {
                                label: '英(ヨン)',
                                words: ['영'],
                                options: ['상어', '영', '딸', '밤'],
                            },
                            {
                                label: '夜(パム)',
                                words: ['밤'],
                                options: ['상어', '밤', '딸', '영'],
                            },
                            {
                                label: '娘(タル)',
                                words: ['딸'],
                                options: ['딸', '영', '상어', '밤'],
                            },
                            {
                                label: '鮫(サノ)',
                                words: ['상어'],
                                options: ['딸', '상어', '영', '밤'],
                            },
                        ],
                    },
                    {
                        type: 'pdf',
                        fileName: 'slide_hangul_11_2.pdf',
                    },                    
                ],
            }, 
        ]
    }
];
