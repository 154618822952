import advanced_peg_method_map_of_japan from '../assets/pdf/advanced_peg_method_map_of_japan.pdf';
import conv_alphabet from '../assets/pdf/conv_alphabet.pdf';
import conv_card from '../assets/pdf/conv_card.pdf';
import conv_number from '../assets/pdf/conv_number.pdf';
import hangul_hansetsuhyo from '../assets/pdf/hangul_hansetsuhyo.pdf';
import national_capitals from '../assets/pdf/national_capitals.pdf';
import slide_1st from '../assets/pdf/slide_1st.pdf';
import slide_2nd from '../assets/pdf/slide_2nd.pdf';
import slide_advanced_peg_method_01_1 from '../assets/pdf/slide_advanced_peg_method_01_1.pdf';
import slide_advanced_peg_method_02_1 from '../assets/pdf/slide_advanced_peg_method_02_1.pdf';
import slide_advanced_peg_method_02_2 from '../assets/pdf/slide_advanced_peg_method_02_2.pdf';
import slide_advanced_peg_method_03_1 from '../assets/pdf/slide_advanced_peg_method_03_1.pdf';
import slide_advanced_peg_method_03_2 from '../assets/pdf/slide_advanced_peg_method_03_2.pdf';
import slide_advanced_peg_method_04_1 from '../assets/pdf/slide_advanced_peg_method_04_1.pdf';
import slide_advanced_peg_method_04_2 from '../assets/pdf/slide_advanced_peg_method_04_2.pdf';
import slide_advanced_peg_method_05_1 from '../assets/pdf/slide_advanced_peg_method_05_1.pdf';
import slide_advanced_peg_method_05_2 from '../assets/pdf/slide_advanced_peg_method_05_2.pdf';
import slide_advanced_peg_method_06_1 from '../assets/pdf/slide_advanced_peg_method_06_1.pdf';
import slide_alphabet_01_1 from '../assets/pdf/slide_alphabet_01_1.pdf';
import slide_alphabet_02_1 from '../assets/pdf/slide_alphabet_02_1.pdf';
import slide_alphabet_02_2 from '../assets/pdf/slide_alphabet_02_2.pdf';
import slide_alphabet_03_1 from '../assets/pdf/slide_alphabet_03_1.pdf';
import slide_alphabet_03_2 from '../assets/pdf/slide_alphabet_03_2.pdf';
import slide_alphabet_04_1 from '../assets/pdf/slide_alphabet_04_1.pdf';
import slide_alphabet_04_2 from '../assets/pdf/slide_alphabet_04_2.pdf';
import slide_alphabet_05_1 from '../assets/pdf/slide_alphabet_05_1.pdf';
import slide_alphabet_05_2 from '../assets/pdf/slide_alphabet_05_2.pdf';
import slide_alphabet_06_1 from '../assets/pdf/slide_alphabet_06_1.pdf';
import slide_alphabet_06_2 from '../assets/pdf/slide_alphabet_06_2.pdf';
import slide_alphabet_07_1 from '../assets/pdf/slide_alphabet_07_1.pdf';
import slide_alphabet_07_2 from '../assets/pdf/slide_alphabet_07_2.pdf';
import slide_alphabet_08_1 from '../assets/pdf/slide_alphabet_08_1.pdf';
import slide_alphabet_08_2 from '../assets/pdf/slide_alphabet_08_2.pdf';
import slide_card_01_1 from '../assets/pdf/slide_card_01_1.pdf';
import slide_card_02_1 from '../assets/pdf/slide_card_02_1.pdf';
import slide_card_03_1 from '../assets/pdf/slide_card_03_1.pdf';
import slide_card_04_1 from '../assets/pdf/slide_card_04_1.pdf';
import slide_card_04_2 from '../assets/pdf/slide_card_04_2.pdf';
import slide_card_05_1 from '../assets/pdf/slide_card_05_1.pdf';
import slide_card_05_2 from '../assets/pdf/slide_card_05_2.pdf';
import slide_card_06_1 from '../assets/pdf/slide_card_06_1.pdf';
import slide_card_06_2 from '../assets/pdf/slide_card_06_2.pdf';
import slide_card_07_1 from '../assets/pdf/slide_card_07_1.pdf';
import slide_card_07_2 from '../assets/pdf/slide_card_07_2.pdf';
import slide_card_08_1 from '../assets/pdf/slide_card_08_1.pdf';
import slide_card_08_2 from '../assets/pdf/slide_card_08_2.pdf';
import slide_card_09_1 from '../assets/pdf/slide_card_09_1.pdf';
import slide_card_09_2 from '../assets/pdf/slide_card_09_2.pdf';
import slide_card_10_1 from '../assets/pdf/slide_card_10_1.pdf';
import slide_card_11_1 from '../assets/pdf/slide_card_11_1.pdf';
import slide_circle_ratio_01_1 from '../assets/pdf/slide_circle_ratio_01_1.pdf';
import slide_circle_ratio_02_1 from '../assets/pdf/slide_circle_ratio_02_1.pdf';
import slide_circle_ratio_03_1 from '../assets/pdf/slide_circle_ratio_03_1.pdf';
import slide_circle_ratio_04_1 from '../assets/pdf/slide_circle_ratio_04_1.pdf';
import slide_circle_ratio_05_1 from '../assets/pdf/slide_circle_ratio_05_1.pdf';
import slide_circle_ratio_06_1 from '../assets/pdf/slide_circle_ratio_06_1.pdf';
import slide_circle_ratio_07_1 from '../assets/pdf/slide_circle_ratio_07_1.pdf';
import slide_circle_ratio_08_1 from '../assets/pdf/slide_circle_ratio_08_1.pdf';
import slide_circle_ratio_09_1 from '../assets/pdf/slide_circle_ratio_09_1.pdf';
import slide_english_word_01_1 from '../assets/pdf/slide_english_word_01_1.pdf';
import slide_english_word_02_1 from '../assets/pdf/slide_english_word_02_1.pdf';
import slide_english_word_03_1 from '../assets/pdf/slide_english_word_03_1.pdf';
import slide_english_word_04_1 from '../assets/pdf/slide_english_word_04_1.pdf';
import slide_english_word_04_2 from '../assets/pdf/slide_english_word_04_2.pdf';
import slide_english_word_05_1 from '../assets/pdf/slide_english_word_05_1.pdf';
import slide_english_word_05_2 from '../assets/pdf/slide_english_word_05_2.pdf';
import slide_english_word_06_1 from '../assets/pdf/slide_english_word_06_1.pdf';
import slide_english_word_06_2 from '../assets/pdf/slide_english_word_06_2.pdf';
import slide_english_word_07_1 from '../assets/pdf/slide_english_word_07_1.pdf';
import slide_english_word_08_1 from '../assets/pdf/slide_english_word_08_1.pdf';
import slide_english_word_09_1 from '../assets/pdf/slide_english_word_09_1.pdf';
import slide_english_word_10_1 from '../assets/pdf/slide_english_word_10_1.pdf';
import slide_english_word_11_1 from '../assets/pdf/slide_english_word_11_1.pdf';
import slide_english_word_12_1 from '../assets/pdf/slide_english_word_12_1.pdf';
import slide_english_word_13_1 from '../assets/pdf/slide_english_word_13_1.pdf';
import slide_english_word_14_1 from '../assets/pdf/slide_english_word_14_1.pdf';
import slide_hangul_01_1 from '../assets/pdf/slide_hangul_01_1.pdf';
import slide_hangul_02_1 from '../assets/pdf/slide_hangul_02_1.pdf';
import slide_hangul_02_2 from '../assets/pdf/slide_hangul_02_2.pdf';
import slide_hangul_03_1 from '../assets/pdf/slide_hangul_03_1.pdf';
import slide_hangul_03_2 from '../assets/pdf/slide_hangul_03_2.pdf';
import slide_hangul_04_1 from '../assets/pdf/slide_hangul_04_1.pdf';
import slide_hangul_04_2 from '../assets/pdf/slide_hangul_04_2.pdf';
import slide_hangul_05_1 from '../assets/pdf/slide_hangul_05_1.pdf';
import slide_hangul_05_2 from '../assets/pdf/slide_hangul_05_2.pdf';
import slide_hangul_06_1 from '../assets/pdf/slide_hangul_06_1.pdf';
import slide_hangul_06_2 from '../assets/pdf/slide_hangul_06_2.pdf';
import slide_hangul_07_1 from '../assets/pdf/slide_hangul_07_1.pdf';
import slide_hangul_07_2 from '../assets/pdf/slide_hangul_07_2.pdf';
import slide_hangul_08_1 from '../assets/pdf/slide_hangul_08_1.pdf';
import slide_hangul_09_1 from '../assets/pdf/slide_hangul_09_1.pdf';
import slide_hangul_10_1 from '../assets/pdf/slide_hangul_10_1.pdf';
import slide_hangul_10_2 from '../assets/pdf/slide_hangul_10_2.pdf';
import slide_hangul_11_1 from '../assets/pdf/slide_hangul_11_1.pdf';
import slide_hangul_11_2 from '../assets/pdf/slide_hangul_11_2.pdf';
import slide_historic_date_01_1 from '../assets/pdf/slide_historic_date_01_1.pdf';
import slide_historic_date_02_1 from '../assets/pdf/slide_historic_date_02_1.pdf';
import slide_historic_date_03_1 from '../assets/pdf/slide_historic_date_03_1.pdf';
import slide_historic_date_03_2 from '../assets/pdf/slide_historic_date_03_2.pdf';
import slide_historic_date_04_1 from '../assets/pdf/slide_historic_date_04_1.pdf';
import slide_historic_date_04_2 from '../assets/pdf/slide_historic_date_04_2.pdf';
import slide_historic_date_05_1 from '../assets/pdf/slide_historic_date_05_1.pdf';
import slide_introduction_01_1 from '../assets/pdf/slide_introduction_01_1.pdf';
import slide_introduction_02_1 from '../assets/pdf/slide_introduction_02_1.pdf';
import slide_introduction_03_1 from '../assets/pdf/slide_introduction_03_1.pdf';
import slide_introduction_04_1 from '../assets/pdf/slide_introduction_04_1.pdf';
import slide_introduction_05_1 from '../assets/pdf/slide_introduction_05_1.pdf';
import slide_introduction_06_1 from '../assets/pdf/slide_introduction_06_1.pdf';
import slide_introduction_07_1 from '../assets/pdf/slide_introduction_07_1.pdf';
import slide_introduction_08_1 from '../assets/pdf/slide_introduction_08_1.pdf';
import slide_introduction_09_1 from '../assets/pdf/slide_introduction_09_1.pdf';
import slide_introduction_10_1 from '../assets/pdf/slide_introduction_10_1.pdf';
import slide_memory_prolongation_01_1 from '../assets/pdf/slide_memory_prolongation_01_1.pdf';
import slide_memory_prolongation_02_1 from '../assets/pdf/slide_memory_prolongation_02_1.pdf';
import slide_memory_prolongation_03_1 from '../assets/pdf/slide_memory_prolongation_03_1.pdf';
import slide_memory_prolongation_04_1 from '../assets/pdf/slide_memory_prolongation_04_1.pdf';
import slide_memory_prolongation_05_1 from '../assets/pdf/slide_memory_prolongation_05_1.pdf';
import slide_memory_prolongation_06_1 from '../assets/pdf/slide_memory_prolongation_06_1.pdf';
import slide_memory_prolongation_07_1 from '../assets/pdf/slide_memory_prolongation_07_1.pdf';
import slide_memory_prolongation_08_1 from '../assets/pdf/slide_memory_prolongation_08_1.pdf';
import slide_memory_sports_01_1 from '../assets/pdf/slide_memory_sports_01_1.pdf';
import slide_memory_sports_02_1 from '../assets/pdf/slide_memory_sports_02_1.pdf';
import slide_memory_sports_03_1 from '../assets/pdf/slide_memory_sports_03_1.pdf';
import slide_memory_sports_04_1 from '../assets/pdf/slide_memory_sports_04_1.pdf';
import slide_memory_sports_05_1 from '../assets/pdf/slide_memory_sports_05_1.pdf';
import slide_memory_sports_06_1 from '../assets/pdf/slide_memory_sports_06_1.pdf';
import slide_memory_sports_06_2 from '../assets/pdf/slide_memory_sports_06_2.pdf';
import slide_memory_sports_07_1 from '../assets/pdf/slide_memory_sports_07_1.pdf';
import slide_memory_sports_07_2 from '../assets/pdf/slide_memory_sports_07_2.pdf';
import slide_memory_sports_08_1 from '../assets/pdf/slide_memory_sports_08_1.pdf';
import slide_memory_sports_08_2 from '../assets/pdf/slide_memory_sports_08_2.pdf';
import slide_memory_sports_09_1 from '../assets/pdf/slide_memory_sports_09_1.pdf';
import slide_method_of_loci_01_1 from '../assets/pdf/slide_method_of_loci_01_1.pdf';
import slide_method_of_loci_02_1 from '../assets/pdf/slide_method_of_loci_02_1.pdf';
import slide_method_of_loci_03_1 from '../assets/pdf/slide_method_of_loci_03_1.pdf';
import slide_method_of_loci_04_1 from '../assets/pdf/slide_method_of_loci_04_1.pdf';
import slide_method_of_loci_05_1 from '../assets/pdf/slide_method_of_loci_05_1.pdf';
import slide_method_of_loci_05_2 from '../assets/pdf/slide_method_of_loci_05_2.pdf';
import slide_method_of_loci_06_1 from '../assets/pdf/slide_method_of_loci_06_1.pdf';
import slide_method_of_loci_07_1 from '../assets/pdf/slide_method_of_loci_07_1.pdf';
import slide_method_of_loci_08_1 from '../assets/pdf/slide_method_of_loci_08_1.pdf';
import slide_method_of_loci_09_1 from '../assets/pdf/slide_method_of_loci_09_1.pdf';
import slide_method_of_loci_09_2 from '../assets/pdf/slide_method_of_loci_09_2.pdf';
import slide_method_of_loci_10_1 from '../assets/pdf/slide_method_of_loci_10_1.pdf';
import slide_method_of_loci_11_1 from '../assets/pdf/slide_method_of_loci_11_1.pdf';
import slide_name_and_face_01_1 from '../assets/pdf/slide_name_and_face_01_1.pdf';
import slide_name_and_face_02_1 from '../assets/pdf/slide_name_and_face_02_1.pdf';
import slide_name_and_face_03_1 from '../assets/pdf/slide_name_and_face_03_1.pdf';
import slide_name_and_face_04_1 from '../assets/pdf/slide_name_and_face_04_1.pdf';
import slide_name_and_face_05_1 from '../assets/pdf/slide_name_and_face_05_1.pdf';
import slide_name_and_face_06_1 from '../assets/pdf/slide_name_and_face_06_1.pdf';
import slide_name_and_face_07_1 from '../assets/pdf/slide_name_and_face_07_1.pdf';
import slide_name_and_face_08_1 from '../assets/pdf/slide_name_and_face_08_1.pdf';
import slide_name_and_face_09_1 from '../assets/pdf/slide_name_and_face_09_1.pdf';
import slide_number_01_1 from '../assets/pdf/slide_number_01_1.pdf';
import slide_number_02_1 from '../assets/pdf/slide_number_02_1.pdf';
import slide_number_02_2 from '../assets/pdf/slide_number_02_2.pdf';
import slide_number_03_1 from '../assets/pdf/slide_number_03_1.pdf';
import slide_number_03_2 from '../assets/pdf/slide_number_03_2.pdf';
import slide_number_04_1 from '../assets/pdf/slide_number_04_1.pdf';
import slide_number_04_2 from '../assets/pdf/slide_number_04_2.pdf';
import slide_number_05_1 from '../assets/pdf/slide_number_05_1.pdf';
import slide_number_05_2 from '../assets/pdf/slide_number_05_2.pdf';
import slide_number_06_1 from '../assets/pdf/slide_number_06_1.pdf';
import slide_number_06_2 from '../assets/pdf/slide_number_06_2.pdf';
import slide_number_07_1 from '../assets/pdf/slide_number_07_1.pdf';
import slide_number_07_2 from '../assets/pdf/slide_number_07_2.pdf';
import slide_number_08_1 from '../assets/pdf/slide_number_08_1.pdf';
import slide_number_08_2 from '../assets/pdf/slide_number_08_2.pdf';
import slide_number_09_1 from '../assets/pdf/slide_number_09_1.pdf';
import slide_number_09_2 from '../assets/pdf/slide_number_09_2.pdf';
import slide_number_10_1 from '../assets/pdf/slide_number_10_1.pdf';
import slide_number_10_2 from '../assets/pdf/slide_number_10_2.pdf';
import slide_number_11_1 from '../assets/pdf/slide_number_11_1.pdf';
import slide_number_11_2 from '../assets/pdf/slide_number_11_2.pdf';
import slide_number_12_1 from '../assets/pdf/slide_number_12_1.pdf';
import slide_number_12_2 from '../assets/pdf/slide_number_12_2.pdf';
import slide_number_13_1 from '../assets/pdf/slide_number_13_1.pdf';
import slide_number_13_2 from '../assets/pdf/slide_number_13_2.pdf';
import slide_number_14_1 from '../assets/pdf/slide_number_14_1.pdf';
import slide_number_14_2 from '../assets/pdf/slide_number_14_2.pdf';
import slide_number_15_1 from '../assets/pdf/slide_number_15_1.pdf';
import slide_number_15_2 from '../assets/pdf/slide_number_15_2.pdf';
import slide_number_16_1 from '../assets/pdf/slide_number_16_1.pdf';
import slide_number_16_2 from '../assets/pdf/slide_number_16_2.pdf';
import slide_number_17_1 from '../assets/pdf/slide_number_17_1.pdf';
import slide_number_17_2 from '../assets/pdf/slide_number_17_2.pdf';
import slide_peg_method_01_1 from '../assets/pdf/slide_peg_method_01_1.pdf';
import slide_peg_method_02_1 from '../assets/pdf/slide_peg_method_02_1.pdf';
import slide_peg_method_02_2 from '../assets/pdf/slide_peg_method_02_2.pdf';
import slide_peg_method_03_1 from '../assets/pdf/slide_peg_method_03_1.pdf';
import slide_peg_method_03_2 from '../assets/pdf/slide_peg_method_03_2.pdf';
import slide_peg_method_04_1 from '../assets/pdf/slide_peg_method_04_1.pdf';
import slide_peg_method_04_2 from '../assets/pdf/slide_peg_method_04_2.pdf';
import slide_peg_method_05_1 from '../assets/pdf/slide_peg_method_05_1.pdf';
import slide_peg_method_05_2 from '../assets/pdf/slide_peg_method_05_2.pdf';
import slide_peg_method_06_1 from '../assets/pdf/slide_peg_method_06_1.pdf';
import slide_peg_method_07_1 from '../assets/pdf/slide_peg_method_07_1.pdf';
import slide_sdgs_01_1 from '../assets/pdf/slide_sdgs_01_1.pdf';
import slide_sdgs_02_1 from '../assets/pdf/slide_sdgs_02_1.pdf';
import slide_sdgs_02_2 from '../assets/pdf/slide_sdgs_02_2.pdf';
import slide_sdgs_03_1 from '../assets/pdf/slide_sdgs_03_1.pdf';
import slide_sdgs_04_1 from '../assets/pdf/slide_sdgs_04_1.pdf';
import slide_sdgs_05_1 from '../assets/pdf/slide_sdgs_05_1.pdf';
import slide_sdgs_05_2 from '../assets/pdf/slide_sdgs_05_2.pdf';
import slide_sdgs_06_1 from '../assets/pdf/slide_sdgs_06_1.pdf';
import slide_sdgs_06_2 from '../assets/pdf/slide_sdgs_06_2.pdf';
import slide_sdgs_07_1 from '../assets/pdf/slide_sdgs_07_1.pdf';
import slide_sdgs_07_2 from '../assets/pdf/slide_sdgs_07_2.pdf';
import slide_sdgs_08_1 from '../assets/pdf/slide_sdgs_08_1.pdf';
import slide_story_method_01_1 from '../assets/pdf/slide_story_method_01_1.pdf';
import slide_story_method_02_1 from '../assets/pdf/slide_story_method_02_1.pdf';
import slide_story_method_02_2 from '../assets/pdf/slide_story_method_02_2.pdf';
import slide_story_method_03_1 from '../assets/pdf/slide_story_method_03_1.pdf';
import slide_story_method_03_2 from '../assets/pdf/slide_story_method_03_2.pdf';
import slide_story_method_04_1 from '../assets/pdf/slide_story_method_04_1.pdf';
import slide_story_method_04_2 from '../assets/pdf/slide_story_method_04_2.pdf';
import slide_story_method_05_1 from '../assets/pdf/slide_story_method_05_1.pdf';
import slide_story_method_05_2 from '../assets/pdf/slide_story_method_05_2.pdf';
import slide_story_method_06_1 from '../assets/pdf/slide_story_method_06_1.pdf';
import slide_story_method_06_2 from '../assets/pdf/slide_story_method_06_2.pdf';
import slide_story_method_07_1 from '../assets/pdf/slide_story_method_07_1.pdf';
import slide_story_method_08_1 from '../assets/pdf/slide_story_method_08_1.pdf';
import slide_textbook_01_1 from '../assets/pdf/slide_textbook_01_1.pdf';
import slide_textbook_02_1 from '../assets/pdf/slide_textbook_02_1.pdf';
import slide_textbook_03_1 from '../assets/pdf/slide_textbook_03_1.pdf';
import slide_textbook_04_1 from '../assets/pdf/slide_textbook_04_1.pdf';
import slide_textbook_05_1 from '../assets/pdf/slide_textbook_05_1.pdf';
import slide_textbook_06_1 from '../assets/pdf/slide_textbook_06_1.pdf';
import slide_textbook_07_1 from '../assets/pdf/slide_textbook_07_1.pdf';
import slide_textbook_08_1 from '../assets/pdf/slide_textbook_08_1.pdf';
import slide_textbook_09_1 from '../assets/pdf/slide_textbook_09_1.pdf';
import slide_world_heritage_01_1 from '../assets/pdf/slide_world_heritage_01_1.pdf';
import slide_world_heritage_01_2 from '../assets/pdf/slide_world_heritage_01_2.pdf';
import slide_world_heritage_02_1 from '../assets/pdf/slide_world_heritage_02_1.pdf';
import slide_world_heritage_03_1 from '../assets/pdf/slide_world_heritage_03_1.pdf';
import slide_world_heritage_03_2 from '../assets/pdf/slide_world_heritage_03_2.pdf';
import slide_world_heritage_04_1 from '../assets/pdf/slide_world_heritage_04_1.pdf';
import slide_world_heritage_05_1 from '../assets/pdf/slide_world_heritage_05_1.pdf';
import slide_world_heritage_05_2 from '../assets/pdf/slide_world_heritage_05_2.pdf';
import slide_world_heritage_06_1 from '../assets/pdf/slide_world_heritage_06_1.pdf';
import slide_world_heritage_06_2 from '../assets/pdf/slide_world_heritage_06_2.pdf';
import slide_world_heritage_07_1 from '../assets/pdf/slide_world_heritage_07_1.pdf';
import slide_world_heritage_07_2 from '../assets/pdf/slide_world_heritage_07_2.pdf';
import slide_world_heritage_08_1 from '../assets/pdf/slide_world_heritage_08_1.pdf';
import slide_world_heritage_08_2 from '../assets/pdf/slide_world_heritage_08_2.pdf';
import visual_english_words from '../assets/pdf/visual_english_words.pdf';

export type PdfResource = {
    path: string;
    numPages: number;
};

export const pdfResources: Map<string, PdfResource> = new Map(
    [
        [
            'advanced_peg_method_map_of_japan.pdf',
            {
                path: advanced_peg_method_map_of_japan,
                numPages: 7,
            },
        ],
        [
            'conv_alphabet.pdf',
            {
                path: conv_alphabet,
                numPages: 1,
            },
        ],
        [
            'conv_card.pdf',
            {
                path: conv_card,
                numPages: 1,
            },
        ],
        [
            'conv_number.pdf',
            {
                path: conv_number,
                numPages: 2,
            },
        ],
        [
            'hangul_hansetsuhyo.pdf',
            {
                path: hangul_hansetsuhyo,
                numPages: 2,
            },
        ],
        [
            'national_capitals.pdf',
            {
                path: national_capitals,
                numPages: 10,
            },
        ],
        [
            'slide_1st.pdf',
            {
                path: slide_1st,
                numPages: 3,
            },
        ],
        [
            'slide_2nd.pdf',
            {
                path: slide_2nd,
                numPages: 2,
            },
        ],
        [
            'slide_advanced_peg_method_01_1.pdf',
            {
                path: slide_advanced_peg_method_01_1,
                numPages: 17,
            },
        ],
        [
            'slide_advanced_peg_method_02_1.pdf',
            {
                path: slide_advanced_peg_method_02_1,
                numPages: 5,
            },
        ],
        [
            'slide_advanced_peg_method_02_2.pdf',
            {
                path: slide_advanced_peg_method_02_2,
                numPages: 2,
            },
        ],
        [
            'slide_advanced_peg_method_03_1.pdf',
            {
                path: slide_advanced_peg_method_03_1,
                numPages: 11,
            },
        ],
        [
            'slide_advanced_peg_method_03_2.pdf',
            {
                path: slide_advanced_peg_method_03_2,
                numPages: 3,
            },
        ],
        [
            'slide_advanced_peg_method_04_1.pdf',
            {
                path: slide_advanced_peg_method_04_1,
                numPages: 8,
            },
        ],
        [
            'slide_advanced_peg_method_04_2.pdf',
            {
                path: slide_advanced_peg_method_04_2,
                numPages: 7,
            },
        ],
        [
            'slide_advanced_peg_method_05_1.pdf',
            {
                path: slide_advanced_peg_method_05_1,
                numPages: 4,
            },
        ],
        [
            'slide_advanced_peg_method_05_2.pdf',
            {
                path: slide_advanced_peg_method_05_2,
                numPages: 2,
            },
        ],
        [
            'slide_advanced_peg_method_06_1.pdf',
            {
                path: slide_advanced_peg_method_06_1,
                numPages: 8,
            },
        ],
        [
            'slide_alphabet_01_1.pdf',
            {
                path: slide_alphabet_01_1,
                numPages: 9,
            },
        ],
        [
            'slide_alphabet_02_1.pdf',
            {
                path: slide_alphabet_02_1,
                numPages: 6,
            },
        ],
        [
            'slide_alphabet_02_2.pdf',
            {
                path: slide_alphabet_02_2,
                numPages: 1,
            },
        ],
        [
            'slide_alphabet_03_1.pdf',
            {
                path: slide_alphabet_03_1,
                numPages: 2,
            },
        ],
        [
            'slide_alphabet_03_2.pdf',
            {
                path: slide_alphabet_03_2,
                numPages: 1,
            },
        ],
        [
            'slide_alphabet_04_1.pdf',
            {
                path: slide_alphabet_04_1,
                numPages: 6,
            },
        ],
        [
            'slide_alphabet_04_2.pdf',
            {
                path: slide_alphabet_04_2,
                numPages: 1,
            },
        ],
        [
            'slide_alphabet_05_1.pdf',
            {
                path: slide_alphabet_05_1,
                numPages: 3,
            },
        ],
        [
            'slide_alphabet_05_2.pdf',
            {
                path: slide_alphabet_05_2,
                numPages: 1,
            },
        ],
        [
            'slide_alphabet_06_1.pdf',
            {
                path: slide_alphabet_06_1,
                numPages: 7,
            },
        ],
        [
            'slide_alphabet_06_2.pdf',
            {
                path: slide_alphabet_06_2,
                numPages: 1,
            },
        ],
        [
            'slide_alphabet_07_1.pdf',
            {
                path: slide_alphabet_07_1,
                numPages: 9,
            },
        ],
        [
            'slide_alphabet_07_2.pdf',
            {
                path: slide_alphabet_07_2,
                numPages: 2,
            },
        ],
        [
            'slide_alphabet_08_1.pdf',
            {
                path: slide_alphabet_08_1,
                numPages: 8,
            },
        ],
        [
            'slide_alphabet_08_2.pdf',
            {
                path: slide_alphabet_08_2,
                numPages: 2,
            },
        ],
        [
            'slide_card_01_1.pdf',
            {
                path: slide_card_01_1,
                numPages: 17,
            },
        ],
        [
            'slide_card_02_1.pdf',
            {
                path: slide_card_02_1,
                numPages: 12,
            },
        ],
        [
            'slide_card_03_1.pdf',
            {
                path: slide_card_03_1,
                numPages: 12,
            },
        ],
        [
            'slide_card_04_1.pdf',
            {
                path: slide_card_04_1,
                numPages: 7,
            },
        ],
        [
            'slide_card_04_2.pdf',
            {
                path: slide_card_04_2,
                numPages: 2,
            },
        ],
        [
            'slide_card_05_1.pdf',
            {
                path: slide_card_05_1,
                numPages: 5,
            },
        ],
        [
            'slide_card_05_2.pdf',
            {
                path: slide_card_05_2,
                numPages: 3,
            },
        ],
        [
            'slide_card_06_1.pdf',
            {
                path: slide_card_06_1,
                numPages: 2,
            },
        ],
        [
            'slide_card_06_2.pdf',
            {
                path: slide_card_06_2,
                numPages: 3,
            },
        ],
        [
            'slide_card_07_1.pdf',
            {
                path: slide_card_07_1,
                numPages: 6,
            },
        ],
        [
            'slide_card_07_2.pdf',
            {
                path: slide_card_07_2,
                numPages: 2,
            },
        ],
        [
            'slide_card_08_1.pdf',
            {
                path: slide_card_08_1,
                numPages: 6,
            },
        ],
        [
            'slide_card_08_2.pdf',
            {
                path: slide_card_08_2,
                numPages: 5,
            },
        ],
        [
            'slide_card_09_1.pdf',
            {
                path: slide_card_09_1,
                numPages: 2,
            },
        ],
        [
            'slide_card_09_2.pdf',
            {
                path: slide_card_09_2,
                numPages: 2,
            },
        ],
        [
            'slide_card_10_1.pdf',
            {
                path: slide_card_10_1,
                numPages: 6,
            },
        ],
        [
            'slide_card_11_1.pdf',
            {
                path: slide_card_11_1,
                numPages: 7,
            },
        ],
        [
            'slide_circle_ratio_01_1.pdf',
            {
                path: slide_circle_ratio_01_1,
                numPages: 13,
            },
        ],
        [
            'slide_circle_ratio_02_1.pdf',
            {
                path: slide_circle_ratio_02_1,
                numPages: 11,
            },
        ],
        [
            'slide_circle_ratio_03_1.pdf',
            {
                path: slide_circle_ratio_03_1,
                numPages: 6,
            },
        ],
        [
            'slide_circle_ratio_04_1.pdf',
            {
                path: slide_circle_ratio_04_1,
                numPages: 19,
            },
        ],
        [
            'slide_circle_ratio_05_1.pdf',
            {
                path: slide_circle_ratio_05_1,
                numPages: 9,
            },
        ],
        [
            'slide_circle_ratio_06_1.pdf',
            {
                path: slide_circle_ratio_06_1,
                numPages: 20,
            },
        ],
        [
            'slide_circle_ratio_07_1.pdf',
            {
                path: slide_circle_ratio_07_1,
                numPages: 17,
            },
        ],
        [
            'slide_circle_ratio_08_1.pdf',
            {
                path: slide_circle_ratio_08_1,
                numPages: 5,
            },
        ],
        [
            'slide_circle_ratio_09_1.pdf',
            {
                path: slide_circle_ratio_09_1,
                numPages: 10,
            },
        ],
        [
            'slide_english_word_01_1.pdf',
            {
                path: slide_english_word_01_1,
                numPages: 12,
            },
        ],
        [
            'slide_english_word_02_1.pdf',
            {
                path: slide_english_word_02_1,
                numPages: 10,
            },
        ],
        [
            'slide_english_word_03_1.pdf',
            {
                path: slide_english_word_03_1,
                numPages: 12,
            },
        ],
        [
            'slide_english_word_04_1.pdf',
            {
                path: slide_english_word_04_1,
                numPages: 10,
            },
        ],
        [
            'slide_english_word_04_2.pdf',
            {
                path: slide_english_word_04_2,
                numPages: 2,
            },
        ],
        [
            'slide_english_word_05_1.pdf',
            {
                path: slide_english_word_05_1,
                numPages: 9,
            },
        ],
        [
            'slide_english_word_05_2.pdf',
            {
                path: slide_english_word_05_2,
                numPages: 3,
            },
        ],
        [
            'slide_english_word_06_1.pdf',
            {
                path: slide_english_word_06_1,
                numPages: 10,
            },
        ],
        [
            'slide_english_word_06_2.pdf',
            {
                path: slide_english_word_06_2,
                numPages: 5,
            },
        ],
        [
            'slide_english_word_07_1.pdf',
            {
                path: slide_english_word_07_1,
                numPages: 8,
            },
        ],
        [
            'slide_english_word_08_1.pdf',
            {
                path: slide_english_word_08_1,
                numPages: 9,
            },
        ],
        [
            'slide_english_word_09_1.pdf',
            {
                path: slide_english_word_09_1,
                numPages: 9,
            },
        ],
        [
            'slide_english_word_10_1.pdf',
            {
                path: slide_english_word_10_1,
                numPages: 8,
            },
        ],
        [
            'slide_english_word_11_1.pdf',
            {
                path: slide_english_word_11_1,
                numPages: 9,
            },
        ],
        [
            'slide_english_word_12_1.pdf',
            {
                path: slide_english_word_12_1,
                numPages: 8,
            },
        ],
        [
            'slide_english_word_13_1.pdf',
            {
                path: slide_english_word_13_1,
                numPages: 9,
            },
        ],
        [
            'slide_english_word_14_1.pdf',
            {
                path: slide_english_word_14_1,
                numPages: 9,
            },
        ],
        [
            'slide_hangul_01_1.pdf',
            {
                path: slide_hangul_01_1,
                numPages: 12,
            },
        ],
        [
            'slide_hangul_02_1.pdf',
            {
                path: slide_hangul_02_1,
                numPages: 5,
            },
        ],
        [
            'slide_hangul_02_2.pdf',
            {
                path: slide_hangul_02_2,
                numPages: 2,
            },
        ],
        [
            'slide_hangul_03_1.pdf',
            {
                path: slide_hangul_03_1,
                numPages: 9,
            },
        ],
        [
            'slide_hangul_03_2.pdf',
            {
                path: slide_hangul_03_2,
                numPages: 2,
            },
        ],
        [
            'slide_hangul_04_1.pdf',
            {
                path: slide_hangul_04_1,
                numPages: 4,
            },
        ],
        [
            'slide_hangul_04_2.pdf',
            {
                path: slide_hangul_04_2,
                numPages: 1,
            },
        ],
        [
            'slide_hangul_05_1.pdf',
            {
                path: slide_hangul_05_1,
                numPages: 6,
            },
        ],
        [
            'slide_hangul_05_2.pdf',
            {
                path: slide_hangul_05_2,
                numPages: 1,
            },
        ],
        [
            'slide_hangul_06_1.pdf',
            {
                path: slide_hangul_06_1,
                numPages: 5,
            },
        ],
        [
            'slide_hangul_06_2.pdf',
            {
                path: slide_hangul_06_2,
                numPages: 2,
            },
        ],
        [
            'slide_hangul_07_1.pdf',
            {
                path: slide_hangul_07_1,
                numPages: 7,
            },
        ],
        [
            'slide_hangul_07_2.pdf',
            {
                path: slide_hangul_07_2,
                numPages: 1,
            },
        ],
        [
            'slide_hangul_08_1.pdf',
            {
                path: slide_hangul_08_1,
                numPages: 10,
            },
        ],
        [
            'slide_hangul_09_1.pdf',
            {
                path: slide_hangul_09_1,
                numPages: 6,
            },
        ],
        [
            'slide_hangul_10_1.pdf',
            {
                path: slide_hangul_10_1,
                numPages: 3,
            },
        ],
        [
            'slide_hangul_10_2.pdf',
            {
                path: slide_hangul_10_2,
                numPages: 2,
            },
        ],
        [
            'slide_hangul_11_1.pdf',
            {
                path: slide_hangul_11_1,
                numPages: 2,
            },
        ],
        [
            'slide_hangul_11_2.pdf',
            {
                path: slide_hangul_11_2,
                numPages: 3,
            },
        ],
        [
            'slide_historic_date_01_1.pdf',
            {
                path: slide_historic_date_01_1,
                numPages: 17,
            },
        ],
        [
            'slide_historic_date_02_1.pdf',
            {
                path: slide_historic_date_02_1,
                numPages: 9,
            },
        ],
        [
            'slide_historic_date_03_1.pdf',
            {
                path: slide_historic_date_03_1,
                numPages: 3,
            },
        ],
        [
            'slide_historic_date_03_2.pdf',
            {
                path: slide_historic_date_03_2,
                numPages: 14,
            },
        ],
        [
            'slide_historic_date_04_1.pdf',
            {
                path: slide_historic_date_04_1,
                numPages: 7,
            },
        ],
        [
            'slide_historic_date_04_2.pdf',
            {
                path: slide_historic_date_04_2,
                numPages: 1,
            },
        ],
        [
            'slide_historic_date_05_1.pdf',
            {
                path: slide_historic_date_05_1,
                numPages: 22,
            },
        ],
        [
            'slide_introduction_01_1.pdf',
            {
                path: slide_introduction_01_1,
                numPages: 8,
            },
        ],
        [
            'slide_introduction_02_1.pdf',
            {
                path: slide_introduction_02_1,
                numPages: 8,
            },
        ],
        [
            'slide_introduction_03_1.pdf',
            {
                path: slide_introduction_03_1,
                numPages: 7,
            },
        ],
        [
            'slide_introduction_04_1.pdf',
            {
                path: slide_introduction_04_1,
                numPages: 6,
            },
        ],
        [
            'slide_introduction_05_1.pdf',
            {
                path: slide_introduction_05_1,
                numPages: 9,
            },
        ],
        [
            'slide_introduction_06_1.pdf',
            {
                path: slide_introduction_06_1,
                numPages: 10,
            },
        ],
        [
            'slide_introduction_07_1.pdf',
            {
                path: slide_introduction_07_1,
                numPages: 14,
            },
        ],
        [
            'slide_introduction_08_1.pdf',
            {
                path: slide_introduction_08_1,
                numPages: 6,
            },
        ],
        [
            'slide_introduction_09_1.pdf',
            {
                path: slide_introduction_09_1,
                numPages: 9,
            },
        ],
        [
            'slide_introduction_10_1.pdf',
            {
                path: slide_introduction_10_1,
                numPages: 10,
            },
        ],
        [
            'slide_memory_prolongation_01_1.pdf',
            {
                path: slide_memory_prolongation_01_1,
                numPages: 14,
            },
        ],
        [
            'slide_memory_prolongation_02_1.pdf',
            {
                path: slide_memory_prolongation_02_1,
                numPages: 7,
            },
        ],
        [
            'slide_memory_prolongation_03_1.pdf',
            {
                path: slide_memory_prolongation_03_1,
                numPages: 9,
            },
        ],
        [
            'slide_memory_prolongation_04_1.pdf',
            {
                path: slide_memory_prolongation_04_1,
                numPages: 11,
            },
        ],
        [
            'slide_memory_prolongation_05_1.pdf',
            {
                path: slide_memory_prolongation_05_1,
                numPages: 9,
            },
        ],
        [
            'slide_memory_prolongation_06_1.pdf',
            {
                path: slide_memory_prolongation_06_1,
                numPages: 10,
            },
        ],
        [
            'slide_memory_prolongation_07_1.pdf',
            {
                path: slide_memory_prolongation_07_1,
                numPages: 11,
            },
        ],
        [
            'slide_memory_prolongation_08_1.pdf',
            {
                path: slide_memory_prolongation_08_1,
                numPages: 13,
            },
        ],
        [
            'slide_memory_sports_01_1.pdf',
            {
                path: slide_memory_sports_01_1,
                numPages: 14,
            },
        ],
        [
            'slide_memory_sports_02_1.pdf',
            {
                path: slide_memory_sports_02_1,
                numPages: 9,
            },
        ],
        [
            'slide_memory_sports_03_1.pdf',
            {
                path: slide_memory_sports_03_1,
                numPages: 15,
            },
        ],
        [
            'slide_memory_sports_04_1.pdf',
            {
                path: slide_memory_sports_04_1,
                numPages: 14,
            },
        ],
        [
            'slide_memory_sports_05_1.pdf',
            {
                path: slide_memory_sports_05_1,
                numPages: 11,
            },
        ],
        [
            'slide_memory_sports_06_1.pdf',
            {
                path: slide_memory_sports_06_1,
                numPages: 3,
            },
        ],
        [
            'slide_memory_sports_06_2.pdf',
            {
                path: slide_memory_sports_06_2,
                numPages: 4,
            },
        ],
        [
            'slide_memory_sports_07_1.pdf',
            {
                path: slide_memory_sports_07_1,
                numPages: 2,
            },
        ],
        [
            'slide_memory_sports_07_2.pdf',
            {
                path: slide_memory_sports_07_2,
                numPages: 4,
            },
        ],
        [
            'slide_memory_sports_08_1.pdf',
            {
                path: slide_memory_sports_08_1,
                numPages: 3,
            },
        ],
        [
            'slide_memory_sports_08_2.pdf',
            {
                path: slide_memory_sports_08_2,
                numPages: 5,
            },
        ],
        [
            'slide_memory_sports_09_1.pdf',
            {
                path: slide_memory_sports_09_1,
                numPages: 14,
            },
        ],
        [
            'slide_method_of_loci_01_1.pdf',
            {
                path: slide_method_of_loci_01_1,
                numPages: 9,
            },
        ],
        [
            'slide_method_of_loci_02_1.pdf',
            {
                path: slide_method_of_loci_02_1,
                numPages: 15,
            },
        ],
        [
            'slide_method_of_loci_03_1.pdf',
            {
                path: slide_method_of_loci_03_1,
                numPages: 10,
            },
        ],
        [
            'slide_method_of_loci_04_1.pdf',
            {
                path: slide_method_of_loci_04_1,
                numPages: 6,
            },
        ],
        [
            'slide_method_of_loci_05_1.pdf',
            {
                path: slide_method_of_loci_05_1,
                numPages: 2,
            },
        ],
        [
            'slide_method_of_loci_05_2.pdf',
            {
                path: slide_method_of_loci_05_2,
                numPages: 1,
            },
        ],
        [
            'slide_method_of_loci_06_1.pdf',
            {
                path: slide_method_of_loci_06_1,
                numPages: 7,
            },
        ],
        [
            'slide_method_of_loci_07_1.pdf',
            {
                path: slide_method_of_loci_07_1,
                numPages: 5,
            },
        ],
        [
            'slide_method_of_loci_08_1.pdf',
            {
                path: slide_method_of_loci_08_1,
                numPages: 12,
            },
        ],
        [
            'slide_method_of_loci_09_1.pdf',
            {
                path: slide_method_of_loci_09_1,
                numPages: 3,
            },
        ],
        [
            'slide_method_of_loci_09_2.pdf',
            {
                path: slide_method_of_loci_09_2,
                numPages: 1,
            },
        ],
        [
            'slide_method_of_loci_10_1.pdf',
            {
                path: slide_method_of_loci_10_1,
                numPages: 7,
            },
        ],
        [
            'slide_method_of_loci_11_1.pdf',
            {
                path: slide_method_of_loci_11_1,
                numPages: 10,
            },
        ],
        [
            'slide_name_and_face_01_1.pdf',
            {
                path: slide_name_and_face_01_1,
                numPages: 9,
            },
        ],
        [
            'slide_name_and_face_02_1.pdf',
            {
                path: slide_name_and_face_02_1,
                numPages: 25,
            },
        ],
        [
            'slide_name_and_face_03_1.pdf',
            {
                path: slide_name_and_face_03_1,
                numPages: 17,
            },
        ],
        [
            'slide_name_and_face_04_1.pdf',
            {
                path: slide_name_and_face_04_1,
                numPages: 18,
            },
        ],
        [
            'slide_name_and_face_05_1.pdf',
            {
                path: slide_name_and_face_05_1,
                numPages: 15,
            },
        ],
        [
            'slide_name_and_face_06_1.pdf',
            {
                path: slide_name_and_face_06_1,
                numPages: 10,
            },
        ],
        [
            'slide_name_and_face_07_1.pdf',
            {
                path: slide_name_and_face_07_1,
                numPages: 16,
            },
        ],
        [
            'slide_name_and_face_08_1.pdf',
            {
                path: slide_name_and_face_08_1,
                numPages: 14,
            },
        ],
        [
            'slide_name_and_face_09_1.pdf',
            {
                path: slide_name_and_face_09_1,
                numPages: 10,
            },
        ],
        [
            'slide_number_01_1.pdf',
            {
                path: slide_number_01_1,
                numPages: 11,
            },
        ],
        [
            'slide_number_02_1.pdf',
            {
                path: slide_number_02_1,
                numPages: 5,
            },
        ],
        [
            'slide_number_02_2.pdf',
            {
                path: slide_number_02_2,
                numPages: 2,
            },
        ],
        [
            'slide_number_03_1.pdf',
            {
                path: slide_number_03_1,
                numPages: 7,
            },
        ],
        [
            'slide_number_03_2.pdf',
            {
                path: slide_number_03_2,
                numPages: 1,
            },
        ],
        [
            'slide_number_04_1.pdf',
            {
                path: slide_number_04_1,
                numPages: 11,
            },
        ],
        [
            'slide_number_04_2.pdf',
            {
                path: slide_number_04_2,
                numPages: 2,
            },
        ],
        [
            'slide_number_05_1.pdf',
            {
                path: slide_number_05_1,
                numPages: 8,
            },
        ],
        [
            'slide_number_05_2.pdf',
            {
                path: slide_number_05_2,
                numPages: 1,
            },
        ],
        [
            'slide_number_06_1.pdf',
            {
                path: slide_number_06_1,
                numPages: 6,
            },
        ],
        [
            'slide_number_06_2.pdf',
            {
                path: slide_number_06_2,
                numPages: 2,
            },
        ],
        [
            'slide_number_07_1.pdf',
            {
                path: slide_number_07_1,
                numPages: 5,
            },
        ],
        [
            'slide_number_07_2.pdf',
            {
                path: slide_number_07_2,
                numPages: 3,
            },
        ],
        [
            'slide_number_08_1.pdf',
            {
                path: slide_number_08_1,
                numPages: 9,
            },
        ],
        [
            'slide_number_08_2.pdf',
            {
                path: slide_number_08_2,
                numPages: 1,
            },
        ],
        [
            'slide_number_09_1.pdf',
            {
                path: slide_number_09_1,
                numPages: 4,
            },
        ],
        [
            'slide_number_09_2.pdf',
            {
                path: slide_number_09_2,
                numPages: 2,
            },
        ],
        [
            'slide_number_10_1.pdf',
            {
                path: slide_number_10_1,
                numPages: 5,
            },
        ],
        [
            'slide_number_10_2.pdf',
            {
                path: slide_number_10_2,
                numPages: 2,
            },
        ],
        [
            'slide_number_11_1.pdf',
            {
                path: slide_number_11_1,
                numPages: 4,
            },
        ],
        [
            'slide_number_11_2.pdf',
            {
                path: slide_number_11_2,
                numPages: 1,
            },
        ],
        [
            'slide_number_12_1.pdf',
            {
                path: slide_number_12_1,
                numPages: 4,
            },
        ],
        [
            'slide_number_12_2.pdf',
            {
                path: slide_number_12_2,
                numPages: 2,
            },
        ],
        [
            'slide_number_13_1.pdf',
            {
                path: slide_number_13_1,
                numPages: 7,
            },
        ],
        [
            'slide_number_13_2.pdf',
            {
                path: slide_number_13_2,
                numPages: 2,
            },
        ],
        [
            'slide_number_14_1.pdf',
            {
                path: slide_number_14_1,
                numPages: 4,
            },
        ],
        [
            'slide_number_14_2.pdf',
            {
                path: slide_number_14_2,
                numPages: 2,
            },
        ],
        [
            'slide_number_15_1.pdf',
            {
                path: slide_number_15_1,
                numPages: 4,
            },
        ],
        [
            'slide_number_15_2.pdf',
            {
                path: slide_number_15_2,
                numPages: 2,
            },
        ],
        [
            'slide_number_16_1.pdf',
            {
                path: slide_number_16_1,
                numPages: 5,
            },
        ],
        [
            'slide_number_16_2.pdf',
            {
                path: slide_number_16_2,
                numPages: 9,
            },
        ],
        [
            'slide_number_17_1.pdf',
            {
                path: slide_number_17_1,
                numPages: 5,
            },
        ],
        [
            'slide_number_17_2.pdf',
            {
                path: slide_number_17_2,
                numPages: 1,
            },
        ],
        [
            'slide_peg_method_01_1.pdf',
            {
                path: slide_peg_method_01_1,
                numPages: 12,
            },
        ],
        [
            'slide_peg_method_02_1.pdf',
            {
                path: slide_peg_method_02_1,
                numPages: 3,
            },
        ],
        [
            'slide_peg_method_02_2.pdf',
            {
                path: slide_peg_method_02_2,
                numPages: 2,
            },
        ],
        [
            'slide_peg_method_03_1.pdf',
            {
                path: slide_peg_method_03_1,
                numPages: 4,
            },
        ],
        [
            'slide_peg_method_03_2.pdf',
            {
                path: slide_peg_method_03_2,
                numPages: 4,
            },
        ],
        [
            'slide_peg_method_04_1.pdf',
            {
                path: slide_peg_method_04_1,
                numPages: 5,
            },
        ],
        [
            'slide_peg_method_04_2.pdf',
            {
                path: slide_peg_method_04_2,
                numPages: 5,
            },
        ],
        [
            'slide_peg_method_05_1.pdf',
            {
                path: slide_peg_method_05_1,
                numPages: 3,
            },
        ],
        [
            'slide_peg_method_05_2.pdf',
            {
                path: slide_peg_method_05_2,
                numPages: 3,
            },
        ],
        [
            'slide_peg_method_06_1.pdf',
            {
                path: slide_peg_method_06_1,
                numPages: 11,
            },
        ],
        [
            'slide_peg_method_07_1.pdf',
            {
                path: slide_peg_method_07_1,
                numPages: 8,
            },
        ],
        [
            'slide_sdgs_01_1.pdf',
            {
                path: slide_sdgs_01_1,
                numPages: 14,
            },
        ],
        [
            'slide_sdgs_02_1.pdf',
            {
                path: slide_sdgs_02_1,
                numPages: 12,
            },
        ],
        [
            'slide_sdgs_02_2.pdf',
            {
                path: slide_sdgs_02_2,
                numPages: 1,
            },
        ],
        [
            'slide_sdgs_03_1.pdf',
            {
                path: slide_sdgs_03_1,
                numPages: 9,
            },
        ],
        [
            'slide_sdgs_04_1.pdf',
            {
                path: slide_sdgs_04_1,
                numPages: 10,
            },
        ],
        [
            'slide_sdgs_05_1.pdf',
            {
                path: slide_sdgs_05_1,
                numPages: 2,
            },
        ],
        [
            'slide_sdgs_05_2.pdf',
            {
                path: slide_sdgs_05_2,
                numPages: 4,
            },
        ],
        [
            'slide_sdgs_06_1.pdf',
            {
                path: slide_sdgs_06_1,
                numPages: 2,
            },
        ],
        [
            'slide_sdgs_06_2.pdf',
            {
                path: slide_sdgs_06_2,
                numPages: 3,
            },
        ],
        [
            'slide_sdgs_07_1.pdf',
            {
                path: slide_sdgs_07_1,
                numPages: 2,
            },
        ],
        [
            'slide_sdgs_07_2.pdf',
            {
                path: slide_sdgs_07_2,
                numPages: 3,
            },
        ],
        [
            'slide_sdgs_08_1.pdf',
            {
                path: slide_sdgs_08_1,
                numPages: 17,
            },
        ],
        [
            'slide_story_method_01_1.pdf',
            {
                path: slide_story_method_01_1,
                numPages: 13,
            },
        ],
        [
            'slide_story_method_02_1.pdf',
            {
                path: slide_story_method_02_1,
                numPages: 5,
            },
        ],
        [
            'slide_story_method_02_2.pdf',
            {
                path: slide_story_method_02_2,
                numPages: 2,
            },
        ],
        [
            'slide_story_method_03_1.pdf',
            {
                path: slide_story_method_03_1,
                numPages: 2,
            },
        ],
        [
            'slide_story_method_03_2.pdf',
            {
                path: slide_story_method_03_2,
                numPages: 2,
            },
        ],
        [
            'slide_story_method_04_1.pdf',
            {
                path: slide_story_method_04_1,
                numPages: 5,
            },
        ],
        [
            'slide_story_method_04_2.pdf',
            {
                path: slide_story_method_04_2,
                numPages: 2,
            },
        ],
        [
            'slide_story_method_05_1.pdf',
            {
                path: slide_story_method_05_1,
                numPages: 6,
            },
        ],
        [
            'slide_story_method_05_2.pdf',
            {
                path: slide_story_method_05_2,
                numPages: 2,
            },
        ],
        [
            'slide_story_method_06_1.pdf',
            {
                path: slide_story_method_06_1,
                numPages: 2,
            },
        ],
        [
            'slide_story_method_06_2.pdf',
            {
                path: slide_story_method_06_2,
                numPages: 5,
            },
        ],
        [
            'slide_story_method_07_1.pdf',
            {
                path: slide_story_method_07_1,
                numPages: 6,
            },
        ],
        [
            'slide_story_method_08_1.pdf',
            {
                path: slide_story_method_08_1,
                numPages: 6,
            },
        ],
        [
            'slide_textbook_01_1.pdf',
            {
                path: slide_textbook_01_1,
                numPages: 8,
            },
        ],
        [
            'slide_textbook_02_1.pdf',
            {
                path: slide_textbook_02_1,
                numPages: 5,
            },
        ],
        [
            'slide_textbook_03_1.pdf',
            {
                path: slide_textbook_03_1,
                numPages: 7,
            },
        ],
        [
            'slide_textbook_04_1.pdf',
            {
                path: slide_textbook_04_1,
                numPages: 6,
            },
        ],
        [
            'slide_textbook_05_1.pdf',
            {
                path: slide_textbook_05_1,
                numPages: 11,
            },
        ],
        [
            'slide_textbook_06_1.pdf',
            {
                path: slide_textbook_06_1,
                numPages: 8,
            },
        ],
        [
            'slide_textbook_07_1.pdf',
            {
                path: slide_textbook_07_1,
                numPages: 14,
            },
        ],
        [
            'slide_textbook_08_1.pdf',
            {
                path: slide_textbook_08_1,
                numPages: 3,
            },
        ],
        [
            'slide_textbook_09_1.pdf',
            {
                path: slide_textbook_09_1,
                numPages: 9,
            },
        ],
        [
            'slide_world_heritage_01_1.pdf',
            {
                path: slide_world_heritage_01_1,
                numPages: 16,
            },
        ],
        [
            'slide_world_heritage_01_2.pdf',
            {
                path: slide_world_heritage_01_2,
                numPages: 2,
            },
        ],
        [
            'slide_world_heritage_02_1.pdf',
            {
                path: slide_world_heritage_02_1,
                numPages: 14,
            },
        ],
        [
            'slide_world_heritage_03_1.pdf',
            {
                path: slide_world_heritage_03_1,
                numPages: 2,
            },
        ],
        [
            'slide_world_heritage_03_2.pdf',
            {
                path: slide_world_heritage_03_2,
                numPages: 10,
            },
        ],
        [
            'slide_world_heritage_04_1.pdf',
            {
                path: slide_world_heritage_04_1,
                numPages: 13,
            },
        ],
        [
            'slide_world_heritage_05_1.pdf',
            {
                path: slide_world_heritage_05_1,
                numPages: 2,
            },
        ],
        [
            'slide_world_heritage_05_2.pdf',
            {
                path: slide_world_heritage_05_2,
                numPages: 5,
            },
        ],
        [
            'slide_world_heritage_06_1.pdf',
            {
                path: slide_world_heritage_06_1,
                numPages: 5,
            },
        ],
        [
            'slide_world_heritage_06_2.pdf',
            {
                path: slide_world_heritage_06_2,
                numPages: 2,
            },
        ],
        [
            'slide_world_heritage_07_1.pdf',
            {
                path: slide_world_heritage_07_1,
                numPages: 16,
            },
        ],
        [
            'slide_world_heritage_07_2.pdf',
            {
                path: slide_world_heritage_07_2,
                numPages: 2,
            },
        ],
        [
            'slide_world_heritage_08_1.pdf',
            {
                path: slide_world_heritage_08_1,
                numPages: 2,
            },
        ],
        [
            'slide_world_heritage_08_2.pdf',
            {
                path: slide_world_heritage_08_2,
                numPages: 19,
            },
        ],
        [
            'visual_english_words.pdf',
            {
                path: visual_english_words,
                numPages: 22,
            },
        ],
    ]
);
