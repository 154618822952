import React from 'react';
import styles from './styles.module.css';
import { CourseColor } from '../../../config/course';
import { CorrectAnswer } from '../../../lib/domSlide';
import { HangulLessonSlide } from '../../../config/course';
import SlidePracticeBackground from '../../atoms/SlidePracticeBackground';
import FontTitle from '../../atoms/FontTitle';
// import Textbox from '../../atoms/Textbox';
import { PrimaryButton } from '../../atoms/Button';

export type SlidePracticeHangulProps = {
    pageIndex: number;
    slideColor: CourseColor;
    isCheckingAnswers: boolean;
    myAnswers: Array<CorrectAnswer>;
    updateMyAnswersWithIndex: (index:number) => (e: React.ChangeEvent<HTMLSelectElement>) => void;
    updateIsCheckingAnswersTrue: (e: React.MouseEvent<HTMLButtonElement>) => void;
} & Omit<HangulLessonSlide, 'type'>;


const SlidePracticeHangul : React.FC<SlidePracticeHangulProps> = ({
    pageIndex,
    slideColor,
    memorizationTitle,
    recallTitle,
    resultTitle = '結果を判定します',
    hasMemorizationSlide = false,
    correctAnswers,
    myAnswers,
    isCheckingAnswers,

    updateMyAnswersWithIndex,
    updateIsCheckingAnswersTrue,
}) => {

    const domSlideClass = styles['dom-slide'];              // スライド領域
    let titleClass = styles['dom-slide-title'];             // タイトル設定
    let answerAreaClass = styles['dom-slide-answerarea'];   // 問題・回答エリア設定
    let itemGroupClass = styles['item-group'];            // 問題 ラベルと回答欄を合わせたエリア
    let itemLabelClass = styles['item-label'];            // 問題ラベル設定 
    let answerColumnClass = styles['input-item'];           // 問題・回答欄設定
    let buttonAreaClass = styles['button-area'];        // ボタン設定

    // 問題数によってcssを変更
    let question_len = correctAnswers.length;

    if (question_len > 10) {
        itemGroupClass += ' ' + styles['many-questions'];
    }
    // if (question_len === 4) {
    //     itemGroupClass += ' ' + styles['four-questions'];
    // }


    // 結果画面
    if (pageIndex === 1 && isCheckingAnswers) {
        answerAreaClass += ' '+ styles['result'];
        answerColumnClass += ' ' + styles['result'];
        itemGroupClass += ' ' + styles['result'];
    }

    let maxLabelLength : number = 0;
    for (let i: number = 0; i < correctAnswers.length; i++) {
        const label = correctAnswers[i].label;
    
        if (label && label.length > maxLabelLength) {
            maxLabelLength = label.length;
        }
    }

    if (maxLabelLength >= 7){
        itemLabelClass += " " + styles["long-label"];
    }

    return (
        <div className={ styles['slide-practice-hangul-table'] }>
            <SlidePracticeBackground bgColor={slideColor} className={domSlideClass}>
                {/* 記憶画面 */}
                {(hasMemorizationSlide && pageIndex === 0) &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{memorizationTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                return (
                                    <div className={itemGroupClass} key={idx}>
                                        <label className={itemLabelClass}>{correctAnswer.label}</label>
                                        <span className={answerColumnClass}>{correctAnswer.words[0]}</span>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                }

                {/* 回答画面 */}
                {((!hasMemorizationSlide || pageIndex === 1) && !isCheckingAnswers) &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{recallTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                return (
                                    <div className={itemGroupClass} key={idx}>
                                        <label className={itemLabelClass}>{correctAnswer.label}</label>
                                        {/* <Textbox
                                        key={idx}
                                        className={answerColumnClass}
                                        value={myAnswers[idx]?.words[0] || []}
                                        onChange={updateMyAnswersWithIndex(idx)}
                                        maxLength={answerMaxLength}
                                        />  */}
                                        <select className={answerColumnClass} key={idx} onChange={updateMyAnswersWithIndex(idx)}>
                                            {/* ダミー(初めに選択されているふうに見えないするためのもの) */}
                                            <option value="" selected></option>
                                            {correctAnswer.options ? (
                                                    correctAnswer.options.map((option, idx2) => (
                                                        <option key={idx2} value={option}>
                                                            {option}
                                                        </option>
                                                    ))
                                                ):null
                                            }
                                        </select>
                                    </div>
                                );
                            })}
                        </div>
                        <div className={buttonAreaClass}>
                            <PrimaryButton className={styles['button']} onClick={updateIsCheckingAnswersTrue}>答え合わせする</PrimaryButton>
                        </div>
                    </div>
                </div>
                }

                {/* 結果画面 */}
                {((!hasMemorizationSlide || pageIndex === 1) && isCheckingAnswers) &&
                <div className={styles['dom-slide-area']}>
                    <FontTitle className={titleClass}>{resultTitle}</FontTitle>
                    <div className={styles['dom-slide-content']}>
                        <div className={answerAreaClass}>
                            {correctAnswers.map((correctAnswer, idx) => {
                                const value = myAnswers[idx]?.words[0] || '';
                                const resultClass = (correctAnswer.words.includes(value))? styles['correct'] : styles['incorrect'];
                                return (
                                    <React.Fragment>
                                        <div className={itemGroupClass} key={idx}>
                                            <label className={itemLabelClass}>{correctAnswer.label}</label>
                                            <span className={answerColumnClass+' '+resultClass}>{myAnswers[idx]?.words[0] || ''}</span>
                                            <span className={answerColumnClass + ' ' + styles['correct-answers']}>{correctAnswer.words[0]}</span>
                                        </div>
                                        {/*<div className={styles['correct-answer']}>{correctAnswer.words[0]}</div>*/}
                                    </React.Fragment>
                                );
                            })}
                        </div>
                    </div>
                </div>
                }
            </SlidePracticeBackground>
        </div>
    );
};

export default SlidePracticeHangul;
